/* eslint-disable @typescript-eslint/no-explicit-any */
import { COLLECTIONS, db, firebase } from '@/firebase'
import { CategoryV3 } from '@/globals/javascript/models/resources-version-3/CategoryV3'
import { CoatingV3 } from '@/globals/javascript/models/resources-version-3/CoatingV3'
import { CoatingTypeV3 } from '@/globals/javascript/models/resources-version-3/CoatingTypeV3'
import { FractionV3 } from '@/globals/javascript/models/resources-version-3/FractionsV3'
import { GroupQuestionV3 } from '@/globals/javascript/models/resources-version-3/GroupQuestionV3'
import { ItemV3 } from '@/globals/javascript/models/resources-version-3/ItemV3'
import { MaterialV3 } from '@/globals/javascript/models/resources-version-3/MaterialV3'
import { QuestionV3 } from '@/globals/javascript/models/resources-version-3/QuestionV3'
import { RoomV3 } from '@/globals/javascript/models/resources-version-3/RoomV3'
import { TagV3 } from '@/globals/javascript/models/resources-version-3/TagV3'
import { TypeGroupV3 } from '@/globals/javascript/models/resources-version-3/TypeGroupV3'
import { TypeV3 } from '@/globals/javascript/models/resources-version-3/TypeV3'
import { IRootState } from '@/store'
import { FunctionVoid } from '@/types/general'
import {
  IAnalysisDeliveryTimeOptionV3,
  IAnalysisTestGroupV3,
  IAnalysisTestV3,
  IAreaV3,
  IColorV3,
  IEWCCodeV3,
  ILaboratoryV3,
  ISampleTypeV3,
  IItemGroupV3,
  IProjectTypeV3,
  IProjectOptionV3,
} from '@/types/resources-version-3'
import { Module } from 'vuex'
import { sortBy } from 'lodash-es'

interface IResourceV3State {
  fractions: {
    idPrefix: string
    nextID: number
    data: FractionV3[]
  }
  materials: {
    idPrefix: string
    nextID: number
    data: MaterialV3[]
  }
  types: {
    idPrefix: string
    nextID: number
    data: TypeV3[]
  }
  typeGroups: {
    idPrefix: string
    nextID: number
    data: TypeGroupV3[]
  }
  coatings: {
    idPrefix: string
    nextID: number
    data: CoatingV3[]
  }
  coatingTypes: {
    idPrefix: string
    nextID: number
    data: CoatingTypeV3[]
  }
  colors: {
    idPrefix: string
    nextID: number
    data: IColorV3[]
  }
  items: {
    idPrefix: string
    nextID: number
    data: ItemV3[]
  }
  questions: {
    idPrefix: string
    nextID: number
    data: QuestionV3[]
  }
  groupQuestions: {
    idPrefix: string
    nextID: number
    data: GroupQuestionV3[]
  }
  categories: {
    idPrefix: string
    nextID: number
    data: CategoryV3[]
  }
  rooms: {
    idPrefix: string
    nextID: number
    data: RoomV3[]
  }
  tags: {
    idPrefix: string
    nextID: number
    data: TagV3[]
  }
  itemGroups: {
    data: IItemGroupV3[]
  }
  areas: {
    data: IAreaV3[]
  }
  ewcCodes: {
    data: IEWCCodeV3[]
  }
  laboratories: {
    data: ILaboratoryV3[]
  }
  analysisDeliveryTimeOptions: {
    data: IAnalysisDeliveryTimeOptionV3[]
  }
  sampleTypes: {
    data: ISampleTypeV3[]
  }
  analysisTestGroups: {
    data: IAnalysisTestGroupV3[]
  }
  analysisTests: {
    data: IAnalysisTestV3[]
  }
  ecoToxAnalysisTestIDList: {
    data: string[]
  }
  pahV1AnalysisTestIDList: {
    data: string[]
  }
  projectTypes: {
    data: IProjectTypeV3[]
  }
  projectOptions: {
    data: IProjectOptionV3[]
  }

  unsubscribeFractionsV3?: FunctionVoid
  unsubscribeMaterialsV3?: FunctionVoid
  unsubscribeTypesV3?: FunctionVoid
  unsubscribeTypeGroupsV3?: FunctionVoid
  unsubscribeCoatingsV3?: FunctionVoid
  unsubscribeCoatingTypesV3?: FunctionVoid
  unsubscribeColorsV3?: FunctionVoid
  unsubscribeItemsV3?: FunctionVoid
  unsubscribeQuestionsV3?: FunctionVoid
  unsubscribeGroupQuestionsV3?: FunctionVoid
  unsubscribeCategoriesV3?: FunctionVoid
  unsubscribeRoomsV3?: FunctionVoid
  unsubscribeTagsV3?: FunctionVoid

  unsubscribeItemGroupsV3?: FunctionVoid
  unsubscribeAreasV3?: FunctionVoid
  unsubscribeEWCCodesV3?: FunctionVoid
  unsubscribeLaboratoriesV3?: FunctionVoid
  unsubscribeAnalysisDeliveryTimeOptionsV3?: FunctionVoid
  unsubscribeSampleTypesV3?: FunctionVoid
  unsubscribeAnalysisTestGroupsV3?: FunctionVoid
  unsubscribeAnalysisTestsV3?: FunctionVoid
  unsubscribeEcoToxAnalysisTestIDListV3?: FunctionVoid
  unsubscribePAHV1AnalysisTestIDListV3?: FunctionVoid
  unsubscribeProjectTypesV3?: FunctionVoid
  unsubscribeProjectOptionsV3?: FunctionVoid
}

export const resourcesStoreV3: Module<IResourceV3State, IRootState> = {
  state: {
    fractions: {
      idPrefix: '',
      nextID: 0,
      data: [],
    },
    materials: {
      idPrefix: '',
      nextID: 0,
      data: [],
    },
    types: {
      idPrefix: '',
      nextID: 0,
      data: [],
    },
    typeGroups: {
      idPrefix: '',
      nextID: 0,
      data: [],
    },
    coatings: {
      idPrefix: '',
      nextID: 0,
      data: [],
    },
    coatingTypes: {
      idPrefix: '',
      nextID: 0,
      data: [],
    },
    colors: {
      idPrefix: '',
      nextID: 0,
      data: [],
    },
    items: {
      idPrefix: '',
      nextID: 0,
      data: [],
    },
    questions: {
      idPrefix: '',
      nextID: 0,
      data: [],
    },
    groupQuestions: {
      idPrefix: '',
      nextID: 0,
      data: [],
    },
    categories: {
      idPrefix: '',
      nextID: 0,
      data: [],
    },
    rooms: {
      idPrefix: '',
      nextID: 0,
      data: [],
    },
    tags: {
      idPrefix: '',
      nextID: 0,
      data: [],
    },
    itemGroups: {
      data: [],
    },
    areas: {
      data: [],
    },
    ewcCodes: {
      data: [],
    },
    laboratories: {
      data: [],
    },
    analysisDeliveryTimeOptions: {
      data: [],
    },
    sampleTypes: {
      data: [],
    },
    analysisTestGroups: {
      data: [],
    },
    analysisTests: {
      data: [],
    },
    ecoToxAnalysisTestIDList: {
      data: [],
    },
    pahV1AnalysisTestIDList: {
      data: [],
    },
    projectTypes: {
      data: [],
    },
    projectOptions: {
      data: [],
    },

    // Unsubscribers
    unsubscribeFractionsV3: undefined,
    unsubscribeMaterialsV3: undefined,
    unsubscribeTypesV3: undefined,
    unsubscribeTypeGroupsV3: undefined,
    unsubscribeCoatingsV3: undefined,
    unsubscribeCoatingTypesV3: undefined,
    unsubscribeColorsV3: undefined,
    unsubscribeItemsV3: undefined,
    unsubscribeQuestionsV3: undefined,
    unsubscribeGroupQuestionsV3: undefined,
    unsubscribeCategoriesV3: undefined,
    unsubscribeRoomsV3: undefined,
    unsubscribeTagsV3: undefined,

    unsubscribeItemGroupsV3: undefined,
    unsubscribeAreasV3: undefined,
    unsubscribeEWCCodesV3: undefined,
    unsubscribeLaboratoriesV3: undefined,
    unsubscribeAnalysisDeliveryTimeOptionsV3: undefined,
    unsubscribeSampleTypesV3: undefined,
    unsubscribeAnalysisTestGroupsV3: undefined,
    unsubscribeAnalysisTestsV3: undefined,
    unsubscribeEcoToxAnalysisTestIDListV3: undefined,
    unsubscribePAHV1AnalysisTestIDListV3: undefined,
    unsubscribeProjectTypesV3: undefined,
    unsubscribeProjectOptionsV3: undefined,
  },
  mutations: {
    updateStateV3: (state, {
      propName,
      propData,
      unsubscribeName,
      unsubscribe,
    }: {
      propName: string,
      propData: any,
      unsubscribeName?: string,
      unsubscribe?: FunctionVoid,
     }) => {
      if (propName in (state as any)) {
        (state as any)[propName] = propData
      }

      if (
        unsubscribeName
        && unsubscribeName in (state as any)
        && !(state as any)[unsubscribeName]
      ) {
        (state as any)[unsubscribeName] = unsubscribe
      }
    },
    resetResourcesV3: (state) => {
      state.fractions.data = []
      state.materials.data = []
      state.types.data = []
      state.typeGroups.data = []
      state.coatings.data = []
      state.coatingTypes.data = []
      state.colors.data = []
      state.items.data = []
      state.questions.data = []
      state.groupQuestions.data = []
      state.categories.data = []
      state.rooms.data = []
      state.tags.data = []

      state.itemGroups.data = []
      state.areas.data = []
      state.ewcCodes.data = []
      state.laboratories.data = []
      state.analysisDeliveryTimeOptions.data = []
      state.sampleTypes.data = []
      state.analysisTestGroups.data = []
      state.analysisTests.data = []
      state.ecoToxAnalysisTestIDList.data = []
      state.pahV1AnalysisTestIDList.data = []
      state.projectTypes.data = []

      if (state.unsubscribeFractionsV3) {
        state.unsubscribeFractionsV3()
        state.unsubscribeFractionsV3 = undefined
      }
      if (state.unsubscribeMaterialsV3) {
        state.unsubscribeMaterialsV3()
        state.unsubscribeMaterialsV3 = undefined
      }
      if (state.unsubscribeTypesV3) {
        state.unsubscribeTypesV3()
        state.unsubscribeTypesV3 = undefined
      }
      if (state.unsubscribeTypeGroupsV3) {
        state.unsubscribeTypeGroupsV3()
        state.unsubscribeTypeGroupsV3 = undefined
      }
      if (state.unsubscribeCoatingsV3) {
        state.unsubscribeCoatingsV3()
        state.unsubscribeCoatingsV3 = undefined
      }
      if (state.unsubscribeCoatingTypesV3) {
        state.unsubscribeCoatingTypesV3()
        state.unsubscribeCoatingTypesV3 = undefined
      }
      if (state.unsubscribeColorsV3) {
        state.unsubscribeColorsV3()
        state.unsubscribeColorsV3 = undefined
      }
      if (state.unsubscribeItemsV3) {
        state.unsubscribeItemsV3()
        state.unsubscribeItemsV3 = undefined
      }
      if (state.unsubscribeQuestionsV3) {
        state.unsubscribeQuestionsV3()
        state.unsubscribeQuestionsV3 = undefined
      }
      if (state.unsubscribeGroupQuestionsV3) {
        state.unsubscribeGroupQuestionsV3()
        state.unsubscribeGroupQuestionsV3 = undefined
      }
      if (state.unsubscribeCategoriesV3) {
        state.unsubscribeCategoriesV3()
        state.unsubscribeCategoriesV3 = undefined
      }
      if (state.unsubscribeRoomsV3) {
        state.unsubscribeRoomsV3()
        state.unsubscribeRoomsV3 = undefined
      }
      if (state.unsubscribeTagsV3) {
        state.unsubscribeTagsV3()
        state.unsubscribeTagsV3 = undefined
      }

      if (state.unsubscribeItemGroupsV3) {
        state.unsubscribeItemGroupsV3()
        state.unsubscribeItemGroupsV3 = undefined
      }
      if (state.unsubscribeAreasV3) {
        state.unsubscribeAreasV3()
        state.unsubscribeAreasV3 = undefined
      }
      if (state.unsubscribeEWCCodesV3) {
        state.unsubscribeEWCCodesV3()
        state.unsubscribeEWCCodesV3 = undefined
      }
      if (state.unsubscribeLaboratoriesV3) {
        state.unsubscribeLaboratoriesV3()
        state.unsubscribeLaboratoriesV3 = undefined
      }
      if (state.unsubscribeAnalysisDeliveryTimeOptionsV3) {
        state.unsubscribeAnalysisDeliveryTimeOptionsV3()
        state.unsubscribeAnalysisDeliveryTimeOptionsV3 = undefined
      }
      if (state.unsubscribeSampleTypesV3) {
        state.unsubscribeSampleTypesV3()
        state.unsubscribeSampleTypesV3 = undefined
      }
      if (state.unsubscribeAnalysisTestGroupsV3) {
        state.unsubscribeAnalysisTestGroupsV3()
        state.unsubscribeAnalysisTestGroupsV3 = undefined
      }
      if (state.unsubscribeAnalysisTestsV3) {
        state.unsubscribeAnalysisTestsV3()
        state.unsubscribeAnalysisTestsV3 = undefined
      }
      if (state.unsubscribeEcoToxAnalysisTestIDListV3) {
        state.unsubscribeEcoToxAnalysisTestIDListV3()
        state.unsubscribeEcoToxAnalysisTestIDListV3 = undefined
      }
      if (state.unsubscribePAHV1AnalysisTestIDListV3) {
        state.unsubscribePAHV1AnalysisTestIDListV3()
        state.unsubscribePAHV1AnalysisTestIDListV3 = undefined
      }
      if (state.unsubscribeProjectTypesV3) {
        state.unsubscribeProjectTypesV3()
        state.unsubscribeProjectTypesV3 = undefined
      }
      if (state.unsubscribeProjectOptionsV3) {
        state.unsubscribeProjectOptionsV3()
        state.unsubscribeProjectOptionsV3 = undefined
      }
    },
  },
  actions: {
    // DB getters
    getFractionsV3: ({ getters, commit }) => {
      if (getters.areFractionsLoadedV3) {
        return
      }

      const unsubscribe = db
        .collection(COLLECTIONS.DB_RESOURCES_V3)
        .doc('-fractions-')
        .onSnapshot((doc) => {
          const docData = doc.data() || {}
          const data = {
            ...docData,
            data: [] as FractionV3[],
          }

          if (docData.data) {
            docData.data.forEach((fraction: any) => {
              data.data.push(new FractionV3(fraction))
            })
          }

          commit('updateStateV3', {
            propName: 'fractions',
            propData: data,
            unsubscribeName: 'unsubscribeFractionsV3',
            unsubscribe,
          })
        })
    },
    getMaterialsV3: ({ getters, commit }) => {
      if (getters.areMaterialsLoadedV3) {
        return
      }

      const unsubscribe = db
        .collection(COLLECTIONS.DB_RESOURCES_V3)
        .doc('-materials-')
        .onSnapshot((doc) => {
          const docData = doc.data() || {}
          const data = {
            ...docData,
            data: [] as MaterialV3[],
          }

          if (docData.data) {
            docData.data.forEach((material: any) => {
              data.data.push(new MaterialV3(material))
            })
          }

          commit('updateStateV3', {
            propName: 'materials',
            propData: data,
            unsubscribeName: 'unsubscribeMaterialsV3',
            unsubscribe,
          })
        })
    },
    getTypesV3: ({ getters, commit }) => {
      if (getters.areTypesLoadedV3) {
        return
      }

      const unsubscribe = db
        .collection(COLLECTIONS.DB_RESOURCES_V3)
        .doc('-types-')
        .onSnapshot((doc) => {
          const docData = doc.data() || {}
          const data = {
            ...docData,
            data: [] as TypeV3[],
          }

          if (docData.data) {
            docData.data.forEach((type: any) => {
              data.data.push(new TypeV3(type))
            })
          }

          commit('updateStateV3', {
            propName: 'types',
            propData: data,
            unsubscribeName: 'unsubscribeTypesV3',
            unsubscribe,
          })
        })
    },
    getTypeGroupsV3: ({ getters, commit }) => {
      if (getters.areTypeGroupsLoadedV3) {
        return
      }

      const unsubscribe = db
        .collection(COLLECTIONS.DB_RESOURCES_V3)
        .doc('-type-groups-')
        .onSnapshot((doc) => {
          const docData = doc.data() || {}
          const data = {
            ...docData,
            data: [] as TypeGroupV3[],
          }

          if (docData.data) {
            docData.data.forEach((typeGroup: any) => {
              data.data.push(new TypeGroupV3(typeGroup))
            })
          }

          commit('updateStateV3', {
            propName: 'typeGroups',
            propData: data,
            unsubscribeName: 'unsubscribeTypeGroupsV3',
            unsubscribe,
          })
        })
    },
    getCoatingsV3: ({ getters, commit }) => {
      if (getters.areCoatingsLoadedV3) {
        return
      }

      const unsubscribe = db
        .collection(COLLECTIONS.DB_RESOURCES_V3)
        .doc('-coating-types-')
        .onSnapshot((doc) => {
          const docData = doc.data() || {}
          const data = {
            ...docData,
            data: [] as CoatingTypeV3[],
          }

          if (docData.data) {
            docData.data.forEach((coatingType: any) => {
              data.data.push(new CoatingTypeV3(coatingType))
            })
          }

          commit('updateStateV3', {
            propName: 'coatingTypes',
            propData: data,
            unsubscribeName: 'unsubscribeCoatingTypesV3',
            unsubscribe,
          })
        })
    },
    getCoatingTypesV3: ({ getters, commit }) => {
      if (getters.areCoatingTypesLoadedV3) {
        return
      }

      const unsubscribe = db
        .collection(COLLECTIONS.DB_RESOURCES_V3)
        .doc('-coatings-')
        .onSnapshot((doc) => {
          const docData = doc.data() || {}
          const data = {
            ...docData,
            data: [] as CoatingV3[],
          }

          if (docData.data) {
            docData.data.forEach((coating: any) => {
              data.data.push(new CoatingV3(coating))
            })
          }

          commit('updateStateV3', {
            propName: 'coatings',
            propData: data,
            unsubscribeName: 'unsubscribeCoatingsV3',
            unsubscribe,
          })
        })
    },
    getColorsV3: ({ getters, commit }) => {
      if (getters.areColorsLoadedV3) {
        return
      }

      const unsubscribe = db
        .collection(COLLECTIONS.DB_RESOURCES_V3)
        .doc('-colors-')
        .onSnapshot((doc) => {
          const docData = doc.data() || {}
          const data = {
            ...docData,
            data: [] as IColorV3[],
          }

          if (docData.data) {
            docData.data.forEach((color: IColorV3) => {
              data.data.push(color)
            })
          }

          commit('updateStateV3', {
            propName: 'colors',
            propData: data,
            unsubscribeName: 'unsubscribeColorsV3',
            unsubscribe,
          })
        })
    },
    getItemsV3: ({ getters, commit }) => {
      if (getters.areItemsLoadedV3) {
        return
      }

      const unsubscribe = db
        .collection(COLLECTIONS.DB_RESOURCES_V3)
        .doc('-items-')
        .onSnapshot((doc) => {
          const docData = doc.data() || {}
          const data = {
            ...docData,
            data: [] as ItemV3[],
          }

          if (docData.data) {
            docData.data.forEach((item: any) => {
              data.data.push(new ItemV3(item))
            })
          }

          commit('updateStateV3', {
            propName: 'items',
            propData: data,
            unsubscribeName: 'unsubscribeItemsV3',
            unsubscribe,
          })
        })
    },
    getQuestionsV3: ({ getters, commit }) => {
      if (getters.areQuestionsLoadedV3) {
        return
      }

      const unsubscribe = db
        .collection(COLLECTIONS.DB_RESOURCES_V3)
        .doc('-questions-')
        .onSnapshot((doc) => {
          const docData = doc.data() || {}
          const data = {
            ...docData,
            data: [] as QuestionV3[],
          }

          if (docData.data) {
            docData.data.forEach((question: any) => {
              data.data.push(new QuestionV3(question))
            })
          }

          commit('updateStateV3', {
            propName: 'questions',
            propData: data,
            unsubscribeName: 'unsubscribeQuestionsV3',
            unsubscribe,
          })
        })
    },
    getGroupQuestionsV3: ({ getters, commit }) => {
      if (getters.areGroupQuestionsLoadedV3) {
        return
      }

      const unsubscribe = db
        .collection(COLLECTIONS.DB_RESOURCES_V3)
        .doc('-group-questions-')
        .onSnapshot((doc) => {
          const docData = doc.data() || {}
          const data = {
            ...docData,
            data: [] as GroupQuestionV3[],
          }

          if (docData.data) {
            docData.data.forEach((GroupQuestion: any) => {
              data.data.push(new GroupQuestionV3(GroupQuestion))
            })
          }

          commit('updateStateV3', {
            propName: 'groupQuestions',
            propData: data,
            unsubscribeName: 'unsubscribeGroupQuestionsV3',
            unsubscribe,
          })
        })
    },
    getCategoriesV3: ({ getters, commit }) => {
      if (getters.areCategoriesLoadedV3) {
        return
      }

      const unsubscribe = db
        .collection(COLLECTIONS.DB_RESOURCES_V3)
        .doc('-categories-')
        .onSnapshot((doc) => {
          const docData = doc.data() || {}
          const data = {
            ...docData,
            data: [] as CategoryV3[],
          }

          if (docData.data) {
            docData.data.forEach((category: any) => {
              data.data.push(new CategoryV3(category))
            })
          }

          commit('updateStateV3', {
            propName: 'categories',
            propData: data,
            unsubscribeName: 'unsubscribeCategoriesV3',
            unsubscribe,
          })
        })
    },
    getRoomsV3: ({ getters, commit }) => {
      if (getters.areRoomsLoadedV3) {
        return
      }

      const unsubscribe = db
        .collection(COLLECTIONS.DB_RESOURCES_V3)
        .doc('-rooms-')
        .onSnapshot((doc) => {
          const docData = doc.data() || {}
          const data = {
            ...docData,
            data: [] as RoomV3[],
          }

          if (docData.data) {
            docData.data.forEach((room: any) => {
              data.data.push(new RoomV3(room))
            })
          }

          commit('updateStateV3', {
            propName: 'rooms',
            propData: data,
            unsubscribeName: 'unsubscribeRoomsV3',
            unsubscribe,
          })
        })
    },
    getTagsV3: ({ getters, commit }) => {
      if (getters.areTagsLoadedV3) {
        return
      }

      const unsubscribe = db
        .collection(COLLECTIONS.DB_RESOURCES_V3)
        .doc('-tags-')
        .onSnapshot((doc) => {
          const docData = doc.data() || {}
          const data = {
            ...docData,
            data: [] as TagV3[],
          }

          if (docData.data) {
            docData.data.forEach((tag: any) => {
              data.data.push(new TagV3(tag))
            })
          }

          commit('updateStateV3', {
            propName: 'tags',
            propData: data,
            unsubscribeName: 'unsubscribeTagsV3',
            unsubscribe,
          })
        })
    },
    getItemGroupsV3: ({ getters, commit }) => {
      if (getters.areItemGroupsLoadedV3) {
        return
      }

      const unsubscribe = db
        .collection(COLLECTIONS.DB_RESOURCES_V3)
        .doc('-item-groups-')
        .onSnapshot((doc) => {
          const docData = doc.data() || {}
          const data = {
            ...docData,
            data: [] as IItemGroupV3[],
          }

          if (docData.data) {
            docData.data.forEach((itemGroup: any) => {
              data.data.push(itemGroup)
            })
          }

          commit('updateStateV3', {
            propName: 'itemGroups',
            propData: data,
            unsubscribeName: 'unsubscribeItemGroupsV3',
            unsubscribe,
          })
        })
    },
    getAreasV3: ({ getters, commit }) => {
      if (getters.areAreasLoadedV3) {
        return
      }

      const unsubscribe = db
        .collection(COLLECTIONS.DB_RESOURCES_V3)
        .doc('-areas-')
        .onSnapshot((doc) => {
          const docData = doc.data() || {}
          const data = {
            ...docData,
            data: [] as IAreaV3[],
          }

          if (docData.data) {
            docData.data.forEach((area: any) => {
              data.data.push(area)
            })
          }

          commit('updateStateV3', {
            propName: 'areas',
            propData: data,
            unsubscribeName: 'unsubscribeAreasV3',
            unsubscribe,
          })
        })
    },
    getEWCCodesV3: ({ getters, commit }) => {
      if (getters.areEWCCodesLoadedV3) {
        return
      }

      const unsubscribe = db
        .collection(COLLECTIONS.DB_RESOURCES_V3)
        .doc('-ewc-codes-')
        .onSnapshot((doc) => {
          const docData = doc.data() || {}
          const data = {
            ...docData,
            data: [] as IEWCCodeV3[],
          }

          if (docData.data) {
            docData.data.forEach((ewcCode: any) => {
              data.data.push(ewcCode)
            })
          }

          commit('updateStateV3', {
            propName: 'ewcCodes',
            propData: data,
            unsubscribeName: 'unsubscribeEWCCodesV3',
            unsubscribe,
          })
        })
    },
    getLaboratoriesV3: ({ getters, commit }) => {
      if (getters.areLaboratoriesLoadedV3) {
        return
      }

      const unsubscribe = db
        .collection(COLLECTIONS.DB_RESOURCES_V3)
        .doc('-laboratories-')
        .onSnapshot((doc) => {
          const docData = doc.data() || {}
          const data = {
            ...docData,
            data: [] as ILaboratoryV3[],
          }

          if (docData.data) {
            docData.data.forEach((laboratory: any) => {
              data.data.push(laboratory)
            })
          }

          commit('updateStateV3', {
            propName: 'laboratories',
            propData: data,
            unsubscribeName: 'unsubscribeLaboratoriesV3',
            unsubscribe,
          })
        })
    },
    getAnalysisDeliveryTimeOptionsV3: ({ getters, commit }) => {
      if (getters.areAnalysisDeliveryTimeOptionsLoadedV3) {
        return
      }

      const unsubscribe = db
        .collection(COLLECTIONS.DB_RESOURCES_V3)
        .doc('-analysis-delivery-time-options-')
        .onSnapshot((doc) => {
          const docData = doc.data() || {}
          const data = {
            ...docData,
            data: [] as IAnalysisDeliveryTimeOptionV3[],
          }

          if (docData.data) {
            docData.data.forEach((deliveryOption: any) => {
              data.data.push(deliveryOption)
            })
          }

          commit('updateStateV3', {
            propName: 'analysisDeliveryTimeOptions',
            propData: data,
            unsubscribeName: 'unsubscribeAnalysisDeliveryTimeOptionsV3',
            unsubscribe,
          })
        })
    },
    getSampleTypesV3: ({ getters, commit }) => {
      if (getters.areSampleTypesLoadedV3) {
        return
      }

      const unsubscribe = db
        .collection(COLLECTIONS.DB_RESOURCES_V3)
        .doc('-sample-types-')
        .onSnapshot((doc) => {
          const docData = doc.data() || {}
          const data = {
            ...docData,
            data: [] as ISampleTypeV3[],
          }

          if (docData.data) {
            docData.data.forEach((sampleType: any) => {
              data.data.push(sampleType)
            })
          }

          commit('updateStateV3', {
            propName: 'sampleTypes',
            propData: data,
            unsubscribeName: 'unsubscribeSampleTypesV3',
            unsubscribe,
          })
        })
    },
    getAnalysisTestGroupsV3: ({ getters, commit }) => {
      if (getters.areAnalysisTestGroupsV3) {
        return
      }

      const unsubscribe = db
        .collection(COLLECTIONS.DB_RESOURCES_V3)
        .doc('-analysis-test-groups-')
        .onSnapshot((doc) => {
          const docData = doc.data() || {}
          const data = {
            ...docData,
            data: [] as IAnalysisTestGroupV3[],
          }

          if (docData.data) {
            docData.data.forEach((analysisTestGroup: any) => {
              data.data.push(analysisTestGroup)
            })
          }

          commit('updateStateV3', {
            propName: 'analysisTestGroups',
            propData: data,
            unsubscribeName: 'unsubscribeAnalysisTestGroupsV3',
            unsubscribe,
          })
        })
    },
    getAnalysisTestsV3: ({ getters, commit }) => {
      if (getters.areAnalysisTestsV3) {
        return
      }

      const unsubscribe = db
        .collection(COLLECTIONS.DB_RESOURCES_V3)
        .doc('-analysis-tests-')
        .onSnapshot((doc) => {
          const docData = doc.data() || {}
          const data = {
            ...docData,
            data: [] as IAnalysisTestV3[],
          }

          if (docData.data) {
            docData.data.forEach((analysisTest: any) => {
              data.data.push(analysisTest)
            })
          }

          commit('updateStateV3', {
            propName: 'analysisTests',
            propData: data,
            unsubscribeName: 'unsubscribeAnalysisTestsV3',
            unsubscribe,
          })
        })
    },
    getEcoToxAnalysisTestIDListV3: ({ getters, commit }) => {
      if (getters.areEcoToxAnalysisTestIDListV3) {
        return
      }

      const unsubscribe = db
        .collection(COLLECTIONS.DB_RESOURCES_V3)
        .doc('-eco-tox-analysis-test-id-list-')
        .onSnapshot((doc) => {
          const docData = doc.data() || {}
          const data = {
            ...docData,
            data: [] as string[],
          }

          if (docData.data) {
            docData.data.forEach((ecoItem: any) => {
              data.data.push(ecoItem)
            })
          }

          commit('updateStateV3', {
            propName: 'ecoToxAnalysisTestIDList',
            propData: data,
            unsubscribeName: 'unsubscribeEcoToxAnalysisTestIDListV3',
            unsubscribe,
          })
        })
    },
    getPAHV1AnalysisTestIDListV3: ({ getters, commit }) => {
      if (getters.arePAHV1AnalysisTestIDListV3) {
        return
      }

      const unsubscribe = db
        .collection(COLLECTIONS.DB_RESOURCES_V3)
        .doc('-pah-v1-analysis-test-id-list-')
        .onSnapshot((doc) => {
          const docData = doc.data() || {}
          const data = {
            ...docData,
            data: [] as string[],
          }

          if (docData.data) {
            docData.data.forEach((item: any) => {
              data.data.push(item)
            })
          }

          commit('updateStateV3', {
            propName: 'pahV1AnalysisTestIDList',
            propData: data,
            unsubscribeName: 'unsubscribePAHV1AnalysisTestIDListV3',
            unsubscribe,
          })
        })
    },
    getProjectTypesV3: ({ getters, commit }) => {
      if (getters.areProjectTypesLoadedV3) {
        return
      }

      const unsubscribe = db
        .collection(COLLECTIONS.DB_RESOURCES_V3)
        .doc('-project-types-')
        .onSnapshot((doc) => {
          const docData = doc.data() || {}
          const data = {
            ...docData,
            data: [] as IProjectTypeV3[],
          }

          if (docData.data) {
            docData.data.forEach((projectType: IProjectTypeV3) => {
              data.data.push(projectType)
            })
          }

          commit('updateStateV3', {
            propName: 'projectTypes',
            propData: data,
            unsubscribeName: 'unsubscribeProjectTypesV3',
            unsubscribe,
          })
        })
    },
    getProjectOptionsV3: ({ getters, commit }) => {
      if (getters.areProjectOptionsLoadedV3) {
        return
      }

      const unsubscribe = db
        .collection(COLLECTIONS.DB_RESOURCES_V3)
        .doc('-project-options-')
        .onSnapshot((doc) => {
          const docData = doc.data() || {}
          const data = {
            ...docData,
            data: [] as IProjectOptionV3[],
          }

          if (docData.data) {
            docData.data.forEach((projectOption: IProjectOptionV3) => {
              data.data.push(projectOption)
            })
          }

          commit('updateStateV3', {
            propName: 'projectOptions',
            propData: data,
            unsubscribeName: 'unsubscribeProjectOptionsV3',
            unsubscribe,
          })
        })
    },

    // DB Setters
    setResourceV3: (store, {
      doc,
      data,
      shouldBumpID = false,
    }) => {
      db
        .collection(COLLECTIONS.DB_RESOURCES_V3)
        .doc(doc)
        .set({
          nextID: firebase.firestore.FieldValue.increment(shouldBumpID ? 1 : 0),
          data: JSON.parse(JSON.stringify(data)),
        }, { merge: true })
    },
  },
  getters: {
    // Loaders
    areFractionsLoadedV3: (state) => !!state.unsubscribeFractionsV3,
    areMaterialsLoadedV3: (state) => !!state.unsubscribeMaterialsV3,
    areTypesLoadedV3: (state) => !!state.unsubscribeTypesV3,
    areTypeGroupsLoadedV3: (state) => !!state.unsubscribeTypeGroupsV3,
    areCoatingsLoadedV3: (state) => !!state.unsubscribeCoatingsV3,
    areCoatingTypesLoadedV3: (state) => !!state.unsubscribeCoatingTypesV3,
    areColorsLoadedV3: (state) => !!state.unsubscribeColorsV3,
    areItemsLoadedV3: (state) => !!state.unsubscribeItemsV3,
    areItemGroupsLoadedV3: (state) => !!state.unsubscribeItemGroupsV3,
    areQuestionsLoadedV3: (state) => !!state.unsubscribeQuestionsV3,
    areGroupQuestionsLoadedV3: (state) => !!state.unsubscribeGroupQuestionsV3,
    areCategoriesLoadedV3: (state) => !!state.unsubscribeCategoriesV3,
    areRoomsLoadedV3: (state) => !!state.unsubscribeRoomsV3,
    areTagsLoadedV3: (state) => !!state.unsubscribeTagsV3,
    areAreasLoadedV3: (state) => !!state.unsubscribeAreasV3,
    areEWCCodesLoadedV3: (state) => !!state.unsubscribeEWCCodesV3,
    areLaboratoriesLoadedV3: (state) => !!state.unsubscribeLaboratoriesV3,
    areAnalysisDeliveryTimeOptionsLoadedV3: (
      state,
    ) => !!state.unsubscribeAnalysisDeliveryTimeOptionsV3,
    areSampleTypesLoadedV3: (state) => !!state.unsubscribeSampleTypesV3,
    areAnalysisTestGroupsV3: (state) => !!state.unsubscribeAnalysisTestGroupsV3,
    areAnalysisTestsV3: (state) => !!state.unsubscribeAnalysisTestsV3,
    areEcoToxAnalysisTestIDListV3: (state) => !!state.unsubscribeEcoToxAnalysisTestIDListV3,
    arePAHV1AnalysisTestIDListV3: (state) => !!state.unsubscribePAHV1AnalysisTestIDListV3,
    areProjectTypesLoadedV3: (state) => !!state.unsubscribeProjectTypesV3,
    areProjectOptionsLoadedV3: (state) => !!state.unsubscribeProjectOptionsV3,

    // Getters
    fractionsAsArrayV3: (state) => state.fractions.data,
    nextFractionIDV3: (state, getters) => {
      if (!getters.areFractionsLoadedV3) {
        return ''
      }
      return state.fractions.idPrefix + state.fractions.nextID
    },

    materialsAsArrayV3: (state) => state.materials.data,
    nextMaterialIDV3: (state, getters) => {
      if (!getters.areMaterialsLoadedV3) {
        return ''
      }
      return state.materials.idPrefix + state.materials.nextID
    },

    typesAsArrayV3: (state) => state.types.data,
    nextTypeIDV3: (state, getters) => {
      if (!getters.areTypesLoadedV3) {
        return ''
      }
      return state.types.idPrefix + state.types.nextID
    },

    typeGroupsAsArrayV3: (state) => state.typeGroups.data,
    nextTypeGroupIDV3: (state, getters) => {
      if (!getters.areTypesLoadedV3) {
        return ''
      }
      return state.typeGroups.idPrefix + state.typeGroups.nextID
    },

    coatingsAsArrayV3: (state) => state.coatings.data,
    nextCoatingIDV3: (state, getters) => {
      if (!getters.areCoatingsLoadedV3) {
        return ''
      }
      return state.coatings.idPrefix + state.coatings.nextID
    },

    coatingTypesAsArrayV3: (state) => state.coatingTypes.data,
    nextCoatingTypeIDV3: (state, getters) => {
      if (!getters.areCoatingTypesLoadedV3) {
        return ''
      }
      return state.coatingTypes.idPrefix + state.coatingTypes.nextID
    },

    colorsAsArrayV3: (state) => state.colors.data,
    nextColorIDV3: (state, getters) => {
      if (!getters.areColorsLoadedV3) {
        return ''
      }
      return state.colors.idPrefix + state.colors.nextID
    },

    itemsAsArrayV3: (state) => state.items.data,
    nextItemIDV3: (state, getters) => {
      if (!getters.areItemsLoadedV3) {
        return ''
      }
      return state.items.idPrefix + state.items.nextID
    },

    questionsAsArrayV3: (state) => state.questions.data,
    nextQuestionIDV3: (state, getters) => {
      if (!getters.areQuestionsLoadedV3) {
        return ''
      }
      return state.questions.idPrefix + state.questions.nextID
    },

    groupQuestionsAsArrayV3: (state) => state.groupQuestions.data,
    nextGroupQuestionIDV3: (state, getters) => {
      if (!getters.areGroupQuestionsLoadedV3) {
        return ''
      }
      return state.groupQuestions.idPrefix + state.groupQuestions.nextID
    },

    categoriesAsArrayV3: (state) => state.categories.data,
    nextCategoryIDV3: (state, getters) => {
      if (!getters.areCategoriesLoadedV3) {
        return ''
      }
      return state.categories.idPrefix + state.categories.nextID
    },

    roomsAsArrayV3: (state) => state.rooms.data,
    nextRoomIDV3: (state, getters) => {
      if (!getters.areRoomsLoadedV3) {
        return ''
      }
      return state.rooms.idPrefix + state.rooms.nextID
    },

    tagsAsArrayV3: (state) => state.tags.data,
    nextTagIDV3: (state, getters) => {
      if (!getters.areTagsLoadedV3) {
        return ''
      }
      return state.tags.idPrefix + state.tags.nextID
    },

    itemGroupsAsArrayV3: (state) => state.itemGroups.data,
    areasAsArrayV3: (state) => state.areas.data,
    ewcCodesAsArrayV3: (state) => state.ewcCodes.data,
    laboratoriesAsArrayV3: (state) => state.laboratories.data,
    analysisDeliveryTimeOptionsAsArrayV3: (state) => state.analysisDeliveryTimeOptions.data,
    sampleTypesAsArrayV3: (state) => sortBy(state.sampleTypes.data, ['order']),
    sampleTypesForSearchSelectAsArrayV3: (state) => sortBy(state.sampleTypes.data.filter((x) => !['ST-8', 'ST-9'].includes(x.id)), ['order']),
    analysisTestGroupsAsArrayV3: (state) => state.analysisTestGroups.data,
    analysisTestsAsArrayV3: (state) => state.analysisTests.data,
    ecoToxAnalysisTestIDListAsArrayV3: (state) => state.ecoToxAnalysisTestIDList.data,
    pahV1AnalysisTestIDListAsArrayV3: (state) => state.pahV1AnalysisTestIDList.data,
    projectTypesAsArrayV3: (state) => state.projectTypes.data,
    projectOptionsAsArrayV3: (state) => state.projectOptions.data,
  },
}
