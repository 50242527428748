import firebase from 'firebase/app'
import 'firebase/firebase-analytics'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/functions'
import store from '@/store'
import { COLLECTIONS, setIDOnCreate } from './collections'

const firebaseConfigDev = {
  apiKey: 'AIzaSyA-qi-BZqQzfojByqjQgV3ko8lbP1aVfUg',
  authDomain: 'milva-pro-dev.firebaseapp.com',
  databaseURL: 'https://milva-pro-dev.firebaseio.com',
  projectId: 'milva-pro-dev',
  storageBucket: 'milva-pro-dev.appspot.com',
  messagingSenderId: '686133547589',
  appId: '1:686133547589:web:2b04e3922192fbb301492c',
  measurementId: 'G-EQSH9VZ3GR',
}

// - Prod
const firebaseConfigProd = {
  apiKey: 'AIzaSyCkkxPMWVLgTVEF7io9MsgtollFjUvpMto',
  authDomain: 'milva-pro-prod.firebaseapp.com',
  databaseURL: 'https://milva-pro-prod.firebaseio.com',
  projectId: 'milva-pro-prod',
  storageBucket: 'milva-pro-prod.appspot.com',
  messagingSenderId: '559861270282',
  appId: '1:559861270282:web:08e8c3f2d3b7c38aca9cf3',
  measurementId: 'G-9MQ9NWPM6M',
}

const firebaseApp = firebase.initializeApp(process.env.NODE_ENV === 'development' ? firebaseConfigDev : firebaseConfigProd)
const db = firebaseApp.firestore()
const auth = firebaseApp.auth()
const storage = firebaseApp.storage()
const functions = firebaseApp.functions('europe-west3')

const emulatorServices = process.env.VUE_APP_EMULATORS || ''

// Start emulator for firestore
if (emulatorServices === 'all' || emulatorServices.includes('firestore')) {
  db.useEmulator('localhost', 5000)
}
// Start emulator for storage
if (emulatorServices === 'all' || emulatorServices.includes('storage')) {
  storage.useEmulator('localhost', 9199)
}
// Start emulator for functions
if (emulatorServices === 'all' || emulatorServices.includes('functions')) {
  functions.useEmulator('localhost', 5001)
}

auth.onAuthStateChanged((user) => {
  store.dispatch('updateUserLoginState', { user })
})

export {
  firebaseApp,
  firebase,
  db,
  auth,
  storage,
  functions,
  COLLECTIONS,
  setIDOnCreate,
}
