const useDevHost = process.env.NODE_ENV !== 'production'

// Cloud function
function compileCloudFunctionHost() {
  // Get correct baseURL
  const emulators = process.env.VUE_APP_EMULATORS

  let baseURL = ''

  if (
    emulators
     && (emulators.indexOf('all') > -1 || emulators.indexOf('functions') > -1)
  ) {
    baseURL = 'http://localhost:5001/milva-pro-dev/europe-west3'
  }
  else {
    baseURL = useDevHost
      ? 'https://europe-west3-milva-pro-dev.cloudfunctions.net'
      : 'https://europe-west3-milva-pro-prod.cloudfunctions.net'
  }

  return baseURL
}

// Export constants
export const HOST = Object.freeze({
  cloudFunction: compileCloudFunctionHost(),
})
