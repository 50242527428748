import store from '@/store'
import { TUser } from '@/types'
import { UnitFields, UnitTypes, UnitTypeTranslations } from '@/types/general'
import { ILogV2 } from '@/types/resources-version-2'
import { mixWB } from '@/globals/javascript/utils/mixins'
import { ICategoryV2 } from './CategoryV2'
import { ITypeV2 } from './TypeV2'

export interface ICategoryTypeMaterialDataItemV2 {
  units: {
    m2: {
      status: 0 | 1 | 2
      translation: UnitTypeTranslations.M2
      fields: {
        thickness: {
          translation: UnitFields.THICKNESS
          unit: UnitTypes.MILLIMETER
          value: number
        }
      }
    },
    meters: {
      status: 0 | 1 | 2
      translation: UnitTypeTranslations.METERS
      fields: {
        width: {
          translation: UnitFields.WIDTH
          unit: UnitTypes.MILLIMETER
          value: number
        }
        height: {
          translation: UnitFields.HEIGHT
          unit: UnitTypes.MILLIMETER
          value: number
        }
      }
    },
    pcs: {
      status: 0 | 1 | 2
      translation: UnitTypeTranslations.PCS
      fields: {
        weight: {
          translation: UnitFields.WEIGHT
          unit: UnitTypes.TONS
          value: number
        }
      }
    }
  }
}

export interface ICategoryTypeMaterialDataV2 {
  [key:string]: ICategoryTypeMaterialDataItemV2
}

export interface ICategoryTypeConstructorV2 {
  id: string
  translation: string
  categoryID: string
  typeID: string
  materialData: ICategoryTypeMaterialDataV2
  options: {
    askForPlaster: boolean
    askForWallpaper: boolean
    askForCoating: boolean
    askForUnderlining: boolean
    isActive: boolean
    isArchived: boolean
  }
  logs: ILogV2[]
}

export interface ICategoryTypeV2 extends ICategoryTypeConstructorV2 {
  setMaterialData(): ICategoryTypeMaterialDataV2
  getTitle(): string
  getCategoryTitle(): string
  getTypeTitle(): string
  getUser(): TUser | undefined
}

export class CategoryTypeV2 implements ICategoryTypeV2 {
  id: string
  translation: string
  categoryID: string
  typeID: string
  materialData: ICategoryTypeMaterialDataV2
  options: {
    askForPlaster: boolean
    askForWallpaper: boolean
    askForCoating: boolean
    askForUnderlining: boolean
    isActive: boolean
    isArchived: boolean
  }
  logs: ILogV2[]

  constructor({
    id = '',
    translation,
    categoryID,
    typeID,
    materialData,
    options,
    logs,
  }: ICategoryTypeConstructorV2) {
    this.id = id
    this.translation = translation || ''
    this.categoryID = categoryID || ''
    this.typeID = typeID
    this.materialData = materialData || []
    this.options = {
      askForPlaster: options.askForPlaster ?? false,
      askForWallpaper: options.askForWallpaper ?? false,
      askForCoating: options.askForCoating ?? true,
      askForUnderlining: options.askForUnderlining || false,
      isActive: options.isActive || true,
      isArchived: options.isArchived || false,
    }
    this.logs = logs || []

    this.materialData = this.setMaterialData()
  }

  setMaterialData(): ICategoryTypeMaterialDataV2 {
    if (!this.typeID) {
      return {}
    }

    const { typesAsArrayV2 } = store.getters
    const newMaterialData: ICategoryTypeMaterialDataV2 = {}
    const type: ITypeV2 = typesAsArrayV2.find((x: ITypeV2) => x.id === this.typeID)

    Object.keys(type.materialData).forEach((materialID: string) => {
      const categoryTypeMaterialDataItem:
        ICategoryTypeMaterialDataItemV2 | undefined = this.materialData[materialID]

      newMaterialData[materialID] = {
        units: {
          m2: {
            status: categoryTypeMaterialDataItem?.units?.m2?.status ?? 1,
            translation: UnitTypeTranslations.M2,
            fields: {
              thickness: {
                translation: UnitFields.THICKNESS,
                unit: UnitTypes.MILLIMETER,
                value: categoryTypeMaterialDataItem?.units?.m2?.fields?.thickness?.value ?? 0,
              },
            },
          },
          meters: {
            status: categoryTypeMaterialDataItem?.units?.meters?.status ?? 1,
            translation: UnitTypeTranslations.METERS,
            fields: {
              width: {
                translation: UnitFields.WIDTH,
                unit: UnitTypes.MILLIMETER,
                value: categoryTypeMaterialDataItem?.units?.meters?.fields?.width?.value ?? 0,
              },
              height: {
                translation: UnitFields.HEIGHT,
                unit: UnitTypes.MILLIMETER,
                value: categoryTypeMaterialDataItem?.units?.meters?.fields?.height?.value ?? 0,
              },
            },
          },
          pcs: {
            status: categoryTypeMaterialDataItem?.units?.pcs?.status ?? 1,
            translation: UnitTypeTranslations.PCS,
            fields: {
              weight: {
                translation: UnitFields.WEIGHT,
                unit: UnitTypes.TONS,
                value: categoryTypeMaterialDataItem?.units?.pcs?.fields?.weight?.value ?? 0,
              },
            },
          },
        },
      }
    })

    return newMaterialData
  }

  getTitle(): string {
    let title = ''

    if (this.translation) {
      title += mixWB(this.translation)
    }

    if (this.typeID) {
      const { typesAsArrayV2 } = store.getters
      const type: ITypeV2 = typesAsArrayV2.find((x: ITypeV2) => x.id === this.typeID)

      if (title) {
        title += ` (${ mixWB(type.translation) })`
      }
      else {
        title += mixWB(type.translation)
      }
    }

    return title
  }

  getTypeTitle(): string {
    if (!this.typeID) {
      return ''
    }

    const { typesAsArrayV2 } = store.getters
    const type: ITypeV2 = typesAsArrayV2.find(
      (x: ITypeV2) => x.id === this.typeID,
    )
    return mixWB(type.translation)
  }

  getCategoryTitle(): string {
    if (!this.categoryID) {
      return ''
    }

    const { categoriesAsArrayV2 } = store.getters
    const category: ICategoryV2 = categoriesAsArrayV2.find(
      (x: ICategoryV2) => x.id === this.categoryID,
    )
    return mixWB(category.translation)
  }

  getUser() {
    if (!this.logs.length) {
      return undefined
    }

    const { getUserByID } = store.getters

    return getUserByID(this.logs[this.logs.length - 1].userID)
  }

  static newInstance(id: string): ICategoryTypeV2 {
    return new CategoryTypeV2({
      id,
      translation: '',
      categoryID: '',
      typeID: '',
      materialData: {},
      options: {
        askForPlaster: false,
        askForWallpaper: false,
        askForCoating: true,
        askForUnderlining: false,
        isActive: true,
        isArchived: false,
      },
      logs: [],
    })
  }
}
