import { COLLECTIONS, db } from '@/firebase'
import { ICountry } from '@/types/countries'
import { FunctionVoid } from '@/types/general'
import { Module } from 'vuex'
import { IRootState } from '.'

interface countriesState {
  countries: ICountry[]

  unsubscribeCountries?: FunctionVoid,
}

export const countriesStore: Module<countriesState, IRootState> = {
  state: {
    countries: [],

    // Unsubscribers
    unsubscribeCountries: undefined,
  },
  mutations: {
    updateCountries: (state, { countries, unsubscribe }) => {
      state.countries = countries
      if (!state.unsubscribeCountries) {
        state.unsubscribeCountries = unsubscribe
      }
    },
  },
  actions: {
    getCountries: ({ getters, commit }) => {
      if (getters.areCountriesLoaded) {
        return
      }

      const unsubscribe = db
        .collection(COLLECTIONS.DB_COUNTRIES)
        .onSnapshot((querySnapshot) => {
          commit('updateCountries', {
            countries: querySnapshot.docs.map((doc) => ({
              ...doc.data(),
            })),
            unsubscribe,
          })
        })
    },
  },
  getters: {
    // Loaders
    areCountriesLoaded: (state) => !!state.unsubscribeCountries,

    // Other
    allCountries: (state) => state.countries,
    allActiveCountries: (state) => state.countries.filter((x) => x.isActive),
  },
}
