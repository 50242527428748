import store from '@/store'
import { TUser } from '@/types'
import { UnitFields, UnitTypes, UnitTypeTranslations } from '@/types/general'
import { ILogV2 } from '@/types/resources-version-2'
import { mixWB } from '@/globals/javascript/utils/mixins'
import { ICategoryTypeV2 } from './CategoryTypeV2'
import { IMaterialV2, IMaterialFractionsV2 } from './MaterialV2'

export interface ITypeMaterialDataItemV2 {
  units: {
    m2: {
      isActive: boolean
      translation: UnitTypeTranslations.M2
      fields: {
        thickness: {
          translation: UnitFields.THICKNESS
          unit: UnitTypes.MILLIMETER
          value: number
        }
      }
    },
    meters: {
      isActive: boolean
      translation: UnitTypeTranslations.METERS
      fields: {
        width: {
          translation: UnitFields.WIDTH
          unit: UnitTypes.MILLIMETER
          value: number
        }
        height: {
          translation: UnitFields.HEIGHT
          unit: UnitTypes.MILLIMETER
          value: number
        }
      }
    },
    pcs: {
      isActive: boolean
      translation: UnitTypeTranslations.PCS
      fields: {
        weight: {
          translation: UnitFields.WEIGHT
          unit: UnitTypes.TONS
          value: number
        }
      }
    }
  }
  fractions: IMaterialFractionsV2
}

export interface ITypeMaterialDataV2 {
  [key:string]: ITypeMaterialDataItemV2
}

export interface ITypeQuestionV2 {
  questionID: string
  position: number
  alwaysShow: boolean
}

export interface ITypeContructorV2 {
  id: string
  translation: string
  wdgID: string
  materialIDs: string[]
  materialData: ITypeMaterialDataV2
  sampleIDs: string[]
  questions: ITypeQuestionV2[]
  options: {
    isNeverAlone: boolean
    isShownAlone: boolean
    isNotRecyclable: boolean
    isArchived: boolean
  }
  logs: ILogV2[]
}

export interface ITypeV2 extends ITypeContructorV2 {
  setMaterialData(): ITypeMaterialDataV2
  getCategoryTypes(): ICategoryTypeV2[]
  getMaterials(): string
  getUser(): TUser | undefined
}

export class TypeV2 implements ITypeV2 {
  id: string
  translation: string
  wdgID: string
  materialIDs: string[]
  materialData: ITypeMaterialDataV2
  sampleIDs: string[]
  questions: ITypeQuestionV2[]
  options: {
    isNeverAlone: boolean
    isShownAlone: boolean
    isNotRecyclable: boolean
    isArchived: boolean
  }
  logs: ILogV2[]

  constructor({
    id,
    translation,
    wdgID,
    materialIDs,
    materialData,
    sampleIDs,
    questions,
    options,
    logs,
  }: ITypeContructorV2) {
    this.id = id
    this.translation = translation || ''
    this.wdgID = wdgID || ''
    this.materialIDs = materialIDs || []
    this.materialData = materialData || []
    this.sampleIDs = sampleIDs || []
    this.questions = questions || []
    this.options = {
      isNeverAlone: options.isNeverAlone || false,
      isShownAlone: options.isShownAlone || false,
      isNotRecyclable: options.isNotRecyclable || false,
      isArchived: options.isArchived || false,
    }
    this.logs = logs || []

    this.materialData = this.setMaterialData()
  }

  setMaterialData(): ITypeMaterialDataV2 {
    if (!this.materialIDs.length) {
      return {}
    }

    const newMaterialData: ITypeMaterialDataV2 = {}
    this.materialIDs.forEach((materialID: string) => {
      const typeMaterialData: ITypeMaterialDataItemV2 | undefined = this.materialData[materialID]

      newMaterialData[materialID] = {
        units: {
          m2: {
            isActive: typeMaterialData?.units?.m2?.isActive ?? false,
            translation: UnitTypeTranslations.M2,
            fields: {
              thickness: {
                translation: UnitFields.THICKNESS,
                unit: UnitTypes.MILLIMETER,
                value: typeMaterialData?.units?.m2?.fields?.thickness?.value ?? 0,
              },
            },
          },
          meters: {
            isActive: typeMaterialData?.units?.meters?.isActive ?? false,
            translation: UnitTypeTranslations.METERS,
            fields: {
              width: {
                translation: UnitFields.WIDTH,
                unit: UnitTypes.MILLIMETER,
                value: typeMaterialData?.units?.meters?.fields?.width?.value ?? 0,
              },
              height: {
                translation: UnitFields.HEIGHT,
                unit: UnitTypes.MILLIMETER,
                value: typeMaterialData?.units?.meters?.fields?.height?.value ?? 0,
              },
            },
          },
          pcs: {
            isActive: typeMaterialData?.units?.pcs?.isActive ?? false,
            translation: UnitTypeTranslations.PCS,
            fields: {
              weight: {
                translation: UnitFields.WEIGHT,
                unit: UnitTypes.TONS,
                value: typeMaterialData?.units?.pcs?.fields?.weight?.value ?? 0,
              },
            },
          },
        },
        fractions: {
          fractions: {
            clean: {
              wdcID: 'WDC-1',
              translation: 'CLEAN',
              ewcID: typeMaterialData?.fractions?.fractions?.clean?.ewcID ?? '',
              fractionID: typeMaterialData?.fractions?.fractions?.clean?.fractionID ?? '',
            },
            contaminated: {
              wdcID: 'WDC-2',
              translation: 'CONTAMINATED_WASTE',
              ewcID: typeMaterialData?.fractions?.fractions?.contaminated?.ewcID ?? '',
              fractionID: typeMaterialData?.fractions?.fractions?.contaminated?.fractionID ?? '',
            },
            hazardous: {
              wdcID: 'WDC-3',
              translation: 'HAZARDOUS_WASTE',
              ewcID: typeMaterialData?.fractions?.fractions?.hazardous?.ewcID ?? '',
              fractionID: typeMaterialData?.fractions?.fractions?.hazardous?.fractionID ?? '',
            },
            asbestos: {
              wdcID: 'WDC-4',
              translation: 'ASBESTOS_WASTE',
              ewcID: typeMaterialData?.fractions?.fractions?.asbestos?.ewcID ?? '',
              fractionID: typeMaterialData?.fractions?.fractions?.asbestos?.fractionID ?? '',
            },
            hazardousAsbestos: {
              wdcID: 'WDC-5',
              translation: 'ASBESTOS_AND_HAZARDOUS_WASTE',
              ewcID: typeMaterialData?.fractions?.fractions?.hazardousAsbestos?.ewcID ?? '',
              fractionID: typeMaterialData?.fractions?.fractions?.hazardousAsbestos?.fractionID ?? '',
            },
          },
          source: typeMaterialData?.fractions?.source || '',
        },
      }
    })

    return newMaterialData
  }

  getCategoryTypes(): ICategoryTypeV2[] {
    const { categoryTypesAsArrayV2 } = store.getters
    return categoryTypesAsArrayV2.filter((x: ICategoryTypeV2) => x.typeID === this.id)
  }

  getMaterials(): string {
    const { materialsAsArrayV2 } = store.getters
    const materials: string[] = []

    this.materialIDs.forEach((materialID: string) => {
      const material: IMaterialV2 = materialsAsArrayV2.find((x: IMaterialV2) => x.id === materialID)
      materials.push(mixWB(material.translation))
    })

    return materials.sort().join(', ')
  }

  getUser() {
    if (!this.logs.length) {
      return undefined
    }

    const { getUserByID } = store.getters

    return getUserByID(this.logs[this.logs.length - 1].userID)
  }

  static newInstance(id: string): ITypeV2 {
    return new TypeV2({
      id,
      translation: '',
      wdgID: '',
      materialIDs: [],
      materialData: {},
      sampleIDs: [],
      questions: [],
      options: {
        isNeverAlone: false,
        isShownAlone: false,
        isNotRecyclable: false,
        isArchived: false,
      },
      logs: [],
    })
  }
}
