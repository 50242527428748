/* eslint-disable import/no-unresolved */
import { resourcesVerions2 } from '@/router/admin/resources-version-2'
import { RouteRecordRaw } from 'vue-router'
import { resourcesVerions3 } from './resources-version-3'
import { baseChecklist } from '../checklist'
import { prices } from './prices'

export const adminRoutes: Array<RouteRecordRaw> = [
  {
    name: 'Admin',
    path: '/admin',
    component: () => import(/* webpackChunkName: "admin" */ '../../views/Admin.vue'),
    children: [
      ...prices,
      ...resourcesVerions2,
      ...resourcesVerions3,
      {
        name: 'AllProjects',
        path: '',
        meta: {
          checklist: baseChecklist,
        },
        component: () => import(/* webpackChunkName: "dashboard" */ '../../views/Admin/AllProjects.vue'),
      },
      {
        name: 'HubAccounts',
        path: 'hub-accounts',
        meta: {
          checklist: baseChecklist,
        },
        component: () => import(/* webpackChunkName: "hub-accounts" */ '../../views/Admin/HubAccounts.vue'),
      },
      {
        name: 'HubAccountNew',
        path: 'hub-account/new',
        meta: {
          checklist: baseChecklist,
        },
        component: () => import(/* webpackChunkName: "hub-account" */ '../../views/Admin/HubAccount.vue'),
      },
      {
        name: 'HubAccount',
        path: 'hub-account/:accountID',
        meta: {
          checklist: baseChecklist,
        },
        component: () => import(/* webpackChunkName: "hub-account" */ '../../views/Admin/HubAccount.vue'),
      },
      {
        name: 'HubUsers',
        path: 'hub-users/:accountID?',
        meta: {
          checklist: baseChecklist,
        },
        component: () => import(/* webpackChunkName: "hub-users" */ '../../views/Admin/HubUsers.vue'),
      },
      {
        name: 'HubUserNew',
        path: 'hub-user/new/:userID?',
        meta: {
          checklist: baseChecklist,
          requiresAdmin: true,
        },
        component: () => import(/* webpackChunkName: "hub-new-user" */ '../../views/Admin/HubUser.vue'),
      },
      {
        name: 'ProAccounts',
        path: 'pro-accounts',
        meta: {
          checklist: baseChecklist,
        },
        component: () => import(/* webpackChunkName: "pro-accounts" */ '../../views/Admin/ProAccounts.vue'),
      },
      {
        name: 'ProAccountNew',
        path: 'pro-account/new',
        meta: {
          checklist: baseChecklist,
          requiresAdmin: true,
        },
        component: () => import(/* webpackChunkName: "pro-account" */ '../../views/Admin/ProAccount.vue'),
      },
      {
        name: 'ProAccount',
        path: 'pro-account/:accountID',
        meta: {
          checklist: baseChecklist,
        },
        component: () => import(/* webpackChunkName: "pro-account" */ '../../views/Admin/ProAccount.vue'),
      },
      {
        name: 'ProUsers',
        path: 'pro-users/:accountID?',
        meta: {
          checklist: baseChecklist,
        },
        component: () => import(/* webpackChunkName: "pro-users" */ '../../views/Admin/ProUsers.vue'),
      },
      {
        name: 'ProUserNew',
        path: 'pro-user/new/:userID?',
        meta: {
          checklist: baseChecklist,
          requiresAdmin: true,
        },
        component: () => import(/* webpackChunkName: "pro-new-user" */ '../../views/Admin/ProUser.vue'),
      },
      {
        name: 'User',
        path: 'user/:userID',
        meta: {
          checklist: baseChecklist,
          requiresAdmin: true,
        },
        component: () => import(/* webpackChunkName: "user" */ '../../views/Admin/User.vue'),
      },
      {
        name: 'Countries',
        path: 'countries',
        meta: {
          checklist: baseChecklist,
          requiresAdmin: true,
        },
        component: () => import(/* webpackChunkName: "countries" */ '../../views/Admin/Countries.vue'),
      },
      {
        name: 'Languages',
        path: 'languages',
        meta: {
          checklist: baseChecklist,
          requiresAdmin: true,
        },
        component: () => import(/* webpackChunkName: "languages" */ '../../views/Admin/Languages.vue'),
      },
      {
        name: 'Translations',
        path: 'translations',
        meta: {
          checklist: baseChecklist,
          requiresAdmin: true,
        },
        component: () => import(/* webpackChunkName: "translations" */ '../../views/Admin/Translations.vue'),
      },
      {
        name: 'SyncToDev',
        path: 'sync-to-dev',
        meta: {
          checklist: baseChecklist,
          requiresAdmin: true,
        },
        component: () => import(/* webpackChunkName: "sync-to-dev" */ '../../views/Admin/SyncToDev.vue'),
      },
      {
        name: 'CustomTypes',
        path: 'resources/custom-types',
        meta: {
          checklist: baseChecklist,
          requiresAdmin: true,
        },
        component: () => import(/* webpackChunkName: "sync-to-dev" */ '../../views/Admin/ResourcesVersion2/CustomTypesV2.vue'),
      },
      {
        name: 'ReusedTypes',
        path: 'resources/reused-types',
        meta: {
          checklist: baseChecklist,
          requiresAdmin: true,
        },
        component: () => import(/* webpackChunkName: "sync-to-dev" */ '../../views/Admin/ResourcesVersion2/ReusedTypesV2.vue'),
      },
      {
        name: 'CustomRooms',
        path: 'resources/custom-rooms',
        meta: {
          checklist: baseChecklist,
          requiresAdmin: true,
        },
        component: () => import(/* webpackChunkName: "sync-to-dev" */ '../../views/Admin/ResourcesVersion2/CustomRoomsV2.vue'),
      },
    ],
  },
]
