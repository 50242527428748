export interface ICategoryTypeGroupV2 {
  id: string
  translation: string
  materialIDs: string[]
}

export class CategoryTypeGroupV2 implements ICategoryTypeGroupV2 {
  id: string
  translation: string
  materialIDs: string[]

  constructor({
    id,
    translation,
    materialIDs,
  }: ICategoryTypeGroupV2) {
    this.id = id
    this.translation = translation || ''
    this.materialIDs = materialIDs || []
  }

  static newInstance(id: string): ICategoryTypeGroupV2 {
    return new CategoryTypeGroupV2({
      id,
      translation: '',
      materialIDs: [],
    })
  }
}
