import { db, COLLECTIONS, functions } from '@/firebase'
import {
  IUserHub, IUserPro, TUser,
} from '@/types'
import { FunctionVoid } from '@/types/general'
import { Module } from 'vuex'
import { isHubUser, isProUser } from '@/globals/javascript/utils/typeGuards'
import { IRootState } from '.'

export interface TUserState {
  users: TUser[]
  unsubscribeUsers?: FunctionVoid
}

export const userStore: Module<TUserState, IRootState> = {
  state: {
    users: [],

    unsubscribeUsers: undefined,
  },
  mutations: {
    updateUsers: (state, { users, unsubscribe }) => {
      state.users = users
      if (!state.unsubscribeUsers) {
        state.unsubscribeUsers = unsubscribe
      }
    },
    resetUsers: (state) => {
      state.users = []
      if (state.unsubscribeUsers) {
        state.unsubscribeUsers()
        state.unsubscribeUsers = undefined
      }
    },
  },
  actions: {
    getUsers: ({ getters, commit }) => {
      if (getters.areUsersLoaded) {
        return
      }

      const unsubscribe = db
        .collection(COLLECTIONS.DB_USERS)
        .onSnapshot((querySnapshot) => {
          commit('updateUsers', {
            users: querySnapshot.docs.map((doc) => ({
              ...doc.data(),
              id: doc.id,
            })),
            unsubscribe,
          })
        })
    },

    updateUser: (store, { data }) => functions.httpsCallable('system_v3_updateUser')({ ...data }),
    createProUser: (store, { data }) => functions.httpsCallable('pro_v3_createNewUser')({ ...data }),
    createHubUser: (store, { data }) => functions.httpsCallable('hub_v1_createNewUser')({ ...data }),
  },
  getters: {
    // Loaders
    areUsersLoaded: (state) => !!state.unsubscribeUsers,

    // Other
    users: (state) => state.users,
    hubUsers: (state) => state.users.filter((user) => isHubUser(user)),
    proUsers: (state) => state.users.filter((user) => isProUser(user)),
    usersAsObject: (state, getters) => getters.users.reduce(
      (prev: {[key:string]: TUser}, user: TUser) => {
        prev[user.id] = user
        return prev
      }, {},
    ),
    usersByAccount: (state) => (accountID: string) => state.users?.filter(
      (user) => (user as IUserPro).accountID === accountID
      || (user as IUserHub).hub?.accountID === accountID,
    ),
    getUserByID: (state) => (userID: string): TUser | undefined => state.users?.find(
      (user) => user.id === userID,
    ),
  },
}
