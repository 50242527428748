import { COLLECTIONS, db, firebase } from '@/firebase'
import { FunctionVoid } from '@/types/general'
import {
  ILanguage,
  ITranslation,
  ITranslationGroupItem,
  ITranslationGroups,
} from '@/types/translations'
import { Module } from 'vuex'
import { IRootState } from '.'

interface translationState {
  languages: ILanguage[]
  translationGroups: ITranslationGroups
  translations: ITranslation

  unsubscribeLanguages?: FunctionVoid,
  unsubscribeTranslationGroups?: FunctionVoid,
}

export const translationStore: Module<translationState, IRootState> = {
  state: {
    languages: [],
    translationGroups: {},
    translations: {},

    // Unsubscribers
    unsubscribeLanguages: undefined,
    unsubscribeTranslationGroups: undefined,
  },
  mutations: {
    updateLanguages: (state, { languages, unsubscribe }) => {
      state.languages = languages
      if (!state.unsubscribeLanguages) {
        state.unsubscribeLanguages = unsubscribe
      }
    },
    updateTranslationGroups: (state, { translationGroups, unsubscribe }) => {
      state.translationGroups = translationGroups
      if (!state.unsubscribeTranslationGroups) {
        state.unsubscribeTranslationGroups = unsubscribe
      }
    },
    updateTranslations: (state, { translations }) => {
      state.translations = translations
    },
    resetTranslations: (state) => {
      state.languages = []
      state.translationGroups = {}
      state.translations = {}

      if (state.unsubscribeLanguages) {
        state.unsubscribeLanguages()
        state.unsubscribeLanguages = undefined
      }
      if (state.unsubscribeTranslationGroups) {
        state.unsubscribeTranslationGroups()
        state.unsubscribeTranslationGroups = undefined
      }
    },
  },
  actions: {
    getLanguages: ({ getters, commit }) => {
      if (getters.areLanguagesLoaded) {
        return
      }

      const unsubscribe = db
        .collection(COLLECTIONS.DB_LANGUAGES)
        .onSnapshot((querySnapshot) => {
          commit('updateLanguages', {
            languages: querySnapshot.docs.map((doc) => ({
              ...doc.data(),
            })),
            unsubscribe,
          })
        })
    },
    getTranslationGroups: ({ getters, commit, dispatch }) => {
      if (getters.areTranslationGroupsLoaded) {
        return
      }

      const unsubscribe = db
        .collection(COLLECTIONS.DB_TRANSLATIONS)
        .onSnapshot((querySnapshot) => {
          commit('updateTranslationGroups', {
            translationGroups: querySnapshot.docs.reduce((prev: ITranslationGroups, doc) => {
              prev[doc.id] = {
                ...doc.data(),
              }
              return prev
            }, {}),
            unsubscribe,
          })
          dispatch('updateTranslations')
        })
    },
    updateTranslations: ({ getters, commit }) => {
      const translations: ITranslation = {}
      const { translationGroups } = getters
      // Loop through each group of languanges
      Object.keys(translationGroups).forEach((locale) => {
        const group: ITranslationGroupItem = translationGroups[locale]

        // Loop through each translation in group
        Object.keys(group).forEach((translationKey) => {
          if (!translations.hasOwnProperty(translationKey)) {
            translations[translationKey] = {}

            // Add each language
            getters.languages.forEach((lang: ILanguage) => {
              translations[translationKey][lang.locale] = ''
            })
          }

          translations[translationKey][locale] = group[translationKey]
        })
      })
      commit('updateTranslations', {
        translations,
      })
    },
    addTranslation: (store, { locale, key, value }) => {
      db
        .collection(COLLECTIONS.DB_TRANSLATIONS)
        .doc(locale)
        .set({
          [key]: value,
        }, { merge: true })
    },
    removeTranslation: (store, { locale, key }) => {
      db
        .collection(COLLECTIONS.DB_TRANSLATIONS)
        .doc(locale)
        .update({
          [key]: firebase.firestore.FieldValue.delete(),
        })
    },
  },
  getters: {
    // Loaders
    areLanguagesLoaded: (state) => !!state.unsubscribeLanguages,
    areTranslationGroupsLoaded: (state) => !!state.unsubscribeTranslationGroups,

    // Other
    languages: (state) => state.languages,
    translationGroups: (state) => state.translationGroups,
    translations: (state) => state.translations,
  },
}
