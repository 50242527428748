import { baseChecklist } from '@/router/checklist'

export const resourcesVerions3 = [
  {
    name: 'FractionsV3',
    path: 'resources/v3/fractions',
    meta: {
      checklist: baseChecklist,
      requiresAdmin: true,
    },
    component: () => import(/* webpackChunkName: "sync-to-dev" */ '../../views/Admin/ResourcesVersion3/FractionsV3.vue'),
  },
  {
    name: 'MaterialsV3',
    path: 'resources/v3/materials',
    meta: {
      checklist: baseChecklist,
      requiresAdmin: true,
    },
    component: () => import(/* webpackChunkName: "sync-to-dev" */ '../../views/Admin/ResourcesVersion3/MaterialsV3.vue'),
  },
  {
    name: 'TypesV3',
    path: 'resources/v3/types',
    meta: {
      checklist: baseChecklist,
      requiresAdmin: true,
    },
    component: () => import(/* webpackChunkName: "sync-to-dev" */ '../../views/Admin/ResourcesVersion3/TypesV3.vue'),
  },
  {
    name: 'TypeGroupsV3',
    path: 'resources/v3/type-groups',
    meta: {
      checklist: baseChecklist,
      requiresAdmin: true,
    },
    component: () => import(/* webpackChunkName: "sync-to-dev" */ '../../views/Admin/ResourcesVersion3/TypeGroupsV3.vue'),
  },
  {
    name: 'CoatingsV3',
    path: 'resources/v3/coatings',
    meta: {
      checklist: baseChecklist,
      requiresAdmin: true,
    },
    component: () => import(/* webpackChunkName: "sync-to-dev" */ '../../views/Admin/ResourcesVersion3/CoatingsV3.vue'),
  },
  {
    name: 'ItemsV3',
    path: 'resources/v3/items',
    meta: {
      checklist: baseChecklist,
      requiresAdmin: true,
    },
    component: () => import(/* webpackChunkName: "sync-to-dev" */ '../../views/Admin/ResourcesVersion3/ItemsV3.vue'),
  },
  {
    name: 'QuestionsV3',
    path: 'resources/v3/questions',
    meta: {
      checklist: baseChecklist,
      requiresAdmin: true,
    },
    component: () => import(/* webpackChunkName: "sync-to-dev" */ '../../views/Admin/ResourcesVersion3/QuestionsV3.vue'),
  },
  {
    name: 'CategoriesV3',
    path: 'resources/v3/categories',
    meta: {
      checklist: baseChecklist,
      requiresAdmin: true,
    },
    component: () => import(/* webpackChunkName: "sync-to-dev" */ '../../views/Admin/ResourcesVersion3/CategoriesV3.vue'),
  },
  {
    name: 'EWCCodesV3',
    path: 'resources/v3/ewc-codes',
    meta: {
      checklist: baseChecklist,
      requiresAdmin: true,
    },
    component: () => import(/* webpackChunkName: "sync-to-dev" */ '../../views/Admin/ResourcesVersion3/EWCCodesV3.vue'),
  },
  {
    name: 'LaboratoriesAndAnalysesV3',
    path: 'resources/v3/laboratories-and-analyses',
    meta: {
      checklist: baseChecklist,
      requiresAdmin: true,
    },
    component: () => import(/* webpackChunkName: "sync-to-dev" */ '../../views/Admin/ResourcesVersion3/LaboratoriesAndAnalysesV3.vue'),
  },
  {
    name: 'RoomsV3',
    path: 'resources/v3/rooms',
    meta: {
      checklist: baseChecklist,
      requiresAdmin: true,
    },
    component: () => import(/* webpackChunkName: "sync-to-dev" */ '../../views/Admin/ResourcesVersion3/RoomsV3.vue'),
  },
  {
    name: 'TagsV3',
    path: 'resources/v3/tags',
    meta: {
      checklist: baseChecklist,
      requiresAdmin: true,
    },
    component: () => import(/* webpackChunkName: "sync-to-dev" */ '../../views/Admin/ResourcesVersion3/TagsV3.vue'),
  },
  {
    name: 'ProjectTypesV3',
    path: 'resources/v3/project-types',
    meta: {
      checklist: baseChecklist,
      requiresAdmin: true,
    },
    component: () => import(/* webpackChunkName: "sync-to-dev" */ '../../views/Admin/ResourcesVersion3/ProjectTypesV3.vue'),
  },
  {
    name: 'RescourceTranslationsV3',
    path: 'resources/v3/resource-translations',
    meta: {
      checklist: baseChecklist,
      requiresAdmin: true,
    },
    component: () => import(/* webpackChunkName: "sync-to-dev" */ '../../views/Admin/ResourcesVersion3/RescourceTranslationsV3.vue'),
  },
]
