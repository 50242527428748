import { db, COLLECTIONS, firebase } from '@/firebase'
import { IAccount } from '@/types'
import { FunctionVoid } from '@/types/general'
import { Module } from 'vuex'
import { IRootState } from '.'

export interface IAccountState {
  accounts: IAccount[]
  unsubscribeAccounts?: FunctionVoid
}

export const accountStore: Module<IAccountState, IRootState> = {
  state: {
    accounts: [],

    unsubscribeAccounts: undefined,
  },
  mutations: {
    updateAccounts: (state, { accounts, unsubscribe }) => {
      state.accounts = accounts
      if (!state.unsubscribeAccounts) {
        state.unsubscribeAccounts = unsubscribe
      }
    },
    resetAccounts: (state) => {
      state.accounts = []
      if (state.unsubscribeAccounts) {
        state.unsubscribeAccounts()
        state.unsubscribeAccounts = undefined
      }
    },
  },
  actions: {
    getAccounts: ({ state, commit }) => new Promise((resolve) => {
      if (state.unsubscribeAccounts) {
        resolve(state.accounts)
        return
      }

      const unsubscribe = db
        .collection(COLLECTIONS.DB_ACCOUNTS)
        .onSnapshot((querySnapshot) => {
          const accounts = querySnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }))
          commit('updateAccounts', {
            accounts,
            unsubscribe,
          })
          resolve(accounts)
        })
    }),

    updateAccount: (store, { data, id }) => {
      const oldAccount = store.getters.getAccountByID(id)

      if (typeof data.isActive !== 'undefined' && oldAccount.isActive !== data.isActive) {
        data.statusHistory = firebase.firestore.FieldValue.arrayUnion({
          state: data.isActive,
          timestamp: firebase.firestore.Timestamp.now(),
        })
      }

      return db
        .collection(COLLECTIONS.DB_ACCOUNTS)
        .doc(id)
        .set({
          ...data,
        }, { merge: true })
    },
    createAccount: async ({ dispatch }, { data }) => {
      const nextAccountNumberID = await dispatch('getNextAccountNumberID')

      const account: IAccount = {
        ...data,
        numberID: nextAccountNumberID,
        nextScreeningNumber: 1337,
        images: {
          mainLogo: '',
          emailLogo: '',
        },
        createdDate: Date.now(),
        statusHistory: [
          {
            state: data.isActive,
            timestamp: firebase.firestore.Timestamp.now(),
          },
        ],
      }

      const docRef = db
        .collection(COLLECTIONS.DB_ACCOUNTS)
        .doc()

      return docRef.set(account)
        .then(() => docRef.id)
    },
  },
  getters: {
    // Loaders
    areAccountsLoaded: (state) => !!state.unsubscribeAccounts,

    // Other
    accounts: (state) => state.accounts,
    accountsAsObject: (state, getters) => getters.accounts.reduce(
      (prev: {[key:string]: IAccount}, account: IAccount) => {
        prev[account.id] = account
        return prev
      }, {},
    ),
    getAccountByID: (state) => (accountID: string): IAccount | undefined => state.accounts?.find(
      (account) => account.id === accountID,
    ),
  },
}
