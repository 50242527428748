/* eslint-disable @typescript-eslint/no-explicit-any */
import { COLLECTIONS, db } from '@/firebase'
import { IRootState } from '@/store'
import { FunctionVoid } from '@/types/general'
import { IPricesLabsItem, IPricesProItem } from '@/types/prices'
import { Module } from 'vuex'

interface IPricesState {
  pricesPro: {
    data: IPricesProItem[]
  }
  pricesLabs: {
    data: IPricesLabsItem[]
  }

  unsubscribePricesPro?: FunctionVoid
  unsubscribePricesLabs?: FunctionVoid
}

export const pricesStore: Module<IPricesState, IRootState> = {
  state: {

    pricesPro: {
      data: [],
    },
    pricesLabs: {
      data: [],
    },

    // Unsubscribers
    unsubscribePricesPro: undefined,
    unsubscribePricesLabs: undefined,
  },
  mutations: {
    updatePricesState: (state, {
      propName,
      propData,
      unsubscribeName,
      unsubscribe,
    }: {
      propName: string,
      propData: Exclude<keyof IPricesState, 'unsubscribePricesPro'|'unsubscribePricesLabs'>,
      unsubscribeName?: string,
      unsubscribe?: FunctionVoid,
     }) => {
      if (propName in (state as any)) {
        (state as any)[propName] = propData
      }

      if (
        unsubscribeName
        && unsubscribeName in (state as any)
        && !(state as any)[unsubscribeName]
      ) {
        (state as any)[unsubscribeName] = unsubscribe
      }
    },
    resetPrices: (state) => {
      state.pricesPro.data = []
      state.pricesLabs.data = []

      if (state.unsubscribePricesPro) {
        state.unsubscribePricesPro()
        state.unsubscribePricesPro = undefined
      }
      if (state.unsubscribePricesLabs) {
        state.unsubscribePricesLabs()
        state.unsubscribePricesLabs = undefined
      }
    },
  },
  actions: {
    // DB getters
    getPricesPro: ({ getters, commit }) => {
      if (getters.arePricesProLoaded) {
        return
      }

      const unsubscribe = db
        .collection(COLLECTIONS.DB_PRICES)
        .doc('-prices-pro-')
        .onSnapshot((doc) => {
          const docData = doc.data() || {}
          const data = {
            ...docData,
            data: [] as IPricesProItem[],
          }

          if (docData.data) {
            docData.data.forEach((priceItem: IPricesProItem) => {
              data.data.push(priceItem)
            })
          }

          commit('updatePricesState', {
            propName: 'pricesPro',
            propData: data,
            unsubscribeName: 'unsubscribePricesPro',
            unsubscribe,
          })
        })
    },
    getPricesLabs: ({ getters, commit }) => {
      if (getters.arePricesLoadedV3) {
        return
      }

      const unsubscribe = db
        .collection(COLLECTIONS.DB_PRICES)
        .doc('-prices-labs-')
        .onSnapshot((doc) => {
          const docData = doc.data() || {}
          const data = {
            ...docData,
            data: [] as IPricesLabsItem[],
          }

          if (docData.data) {
            docData.data.forEach((priceItem: IPricesLabsItem) => {
              data.data.push(priceItem)
            })
          }

          commit('updatePricesState', {
            propName: 'pricesLabs',
            propData: data,
            unsubscribeName: 'unsubscribePricesLabs',
            unsubscribe,
          })
        })
    },

    // DB Setters
    setPrices: (store, {
      doc,
      data,
    }) => {
      db
        .collection(COLLECTIONS.DB_PRICES)
        .doc(doc)
        .set({
          data: JSON.parse(JSON.stringify(data)),
        }, { merge: true })
    },
  },
  getters: {
    // Loaders
    arePricesProLoaded: (state) => !!state.unsubscribePricesPro,
    arePricesLabsLoaded: (state) => !!state.unsubscribePricesLabs,

    // Getters
    pricesProAsArray: (state) => state.pricesPro.data as IPricesProItem[],
    pricesLabsAsArray: (state) => state.pricesLabs.data as IPricesLabsItem[],
  },
}
