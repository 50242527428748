import { firebase } from '@/firebase'
import { IPriceItem } from './types/prices'

export enum StatType {
  CREATED = 'created',
  STARTED = 'started',
  COMPLETED = 'completed',
  REQUISITIONSENT = 'requisitionSent',
  BILLABLE = 'billable',
}
export interface IStat {
  [StatType.CREATED]: number
  [StatType.STARTED]: number
  [StatType.COMPLETED]: number
  [StatType.REQUISITIONSENT]: number
  [StatType.BILLABLE]: number
}
export interface IStats {
  overallStats?: IStat
  monthlyStats?: {
    [monthYear: string]: IStat
  }
}
export interface IAccountPrices {
  other: {
    [key: string]:IPriceItem
  }
  matAnalyses: {
    [key: string]:IPriceItem
  }
  analysisDeliveryTime: {
    [key: string]:IPriceItem
  }
  sampleHandOver: {
    [key: string]:IPriceItem
  }
}

export interface IHubAccount {
  name: string
  id: string
  url: string
  logo: string
  createdAt: string
  isPrivate: true
  contactUserID: string
}
export interface IAccount extends IStats {
  // sampler
  id: string
  numberID: number
  nextQRLabelNumber: number
  nextScreeningNumber: number
  images: {
    mainLogo: string
    emailLogo: string
  },
  createdDate: firebase.firestore.Timestamp | number | undefined, // Needs to looked into
  statusHistory?: {
    state: boolean
    timestamp: firebase.firestore.Timestamp
  }[]

  // About
  name: string
  baseCountry: string
  address: string
  postalCode: string
  city: string
  contactPerson: {
    name: string
    phoneNumber: string
    email: string
  }
  companyInitials: string

  // Settings
  autoOrderCPFromCPScreening: boolean
  autoOrderPAHFromCH: boolean
  isActive: boolean
  useSamplingID: boolean
  canUseSampler: boolean
  hidePrices: boolean
  useMandatoryManualCaseNumbers: boolean
  allowedProjectTypeIDs: string[]
  autoSelectedProjectOptionIDs: string[]
  canUseCustomAddress: boolean

  // Billing
  isInvoicePaymentAllowed: boolean
  isInvoicePaymentSelected: boolean
  billingInfo: {
    customerType: 'business',
    countryCode: 'DK',
    fullName: string,
    email: string,
    phone: string,
    address: string,
    postalCode: string,
    city: string,
    company: string,
    vatNumber: string,
    att: string,
  }

  // Handover
  pickUpPoints:{
    placement: string,
    address: {
      address: string,
      postalCode: string,
      city: string,
    }
  }[]

  // Labs
  allowMilvaEuroFins: boolean
  billPerRequisition: boolean
  primaryLabID: string
  analysisDeliveryTimeOptionID: string
  euroFinsNumber: string
  hojvangNumber: string
  analysisReportEmail: string

  // Prices
  prices: IAccountPrices
}

export enum Role {
  SUPER_USER = 'super_user',
  SCREENER = 'screener',
  DEVELOPER = 'developer'
}
export enum RoleHub {
  SUPER_USER = 'super_user',
}
export interface IUserBase {
  id: string
  fullName: string
  initials: string
  email: string
  phoneNumber: string
  mustUpdatePassword: boolean
  isAdmin: boolean
  firstTimeLogin: boolean
  createdAt: string
  numberID: number
}
export interface IUserHubData {
    accountID: string
    roles: RoleHub[] | []
    projects: string[]
    activatedAt: string
    lastSeen: string
    isActivated: boolean
    isActive: boolean
  }
export interface IUserProData extends IStats {
  roles: Role[]
  accountID: string
  nextPersonalSamplingID: number
  lastSeen: firebase.firestore.Timestamp | number | undefined // Needs to looked into
  lastSeenV3: firebase.firestore.Timestamp | number | undefined // Needs to looked into
  expiryDate: string
  isActive: boolean
  isActivated: boolean
  activatedAt: string
  nextProjectNumberID: number
}
export interface IUserHub extends IUserBase {
  hub: IUserHubData
}

export interface IUserPro extends IUserBase, IUserProData {
}

export type TUser = IUserPro | IUserHub | IUserPro & IUserHub

