import {
  db, COLLECTIONS, firebase, functions,
} from '@/firebase'
import { IAccount } from '@/types'
import { FunctionVoid } from '@/types/general'
import { Module } from 'vuex'
import { IRootState } from '..'

export interface IAccountState {
  accounts: IAccount[]
  unsubscribeAccounts?: FunctionVoid
}

export const accountStore: Module<IAccountState, IRootState> = {
  state: {
    accounts: [],
    unsubscribeAccounts: undefined,
  },
  mutations: {
    updateAccounts: (state, { accounts, unsubscribe }) => {
      state.accounts = accounts
      if (!state.unsubscribeAccounts) {
        state.unsubscribeAccounts = unsubscribe
      }
    },
    resetAccounts: (state) => {
      state.accounts = []
      if (state.unsubscribeAccounts) {
        state.unsubscribeAccounts()
        state.unsubscribeAccounts = undefined
      }
    },
  },
  actions: {
    getAccounts: ({ state, commit }) => new Promise((resolve) => {
      if (state.unsubscribeAccounts) {
        resolve(state.accounts)
        return
      }

      const unsubscribe = db
        .collection(COLLECTIONS.DB_HUB_ACCOUNTS)
        .onSnapshot((querySnapshot) => {
          const accounts = querySnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }))
          commit('updateAccounts', {
            accounts,
            unsubscribe,
          })
          resolve(accounts)
        })
    }),

    updateAccount: (store, { accountData, accountID }) => functions.httpsCallable(
      'hub_v1_updateAccount',
    )({
      accountID,
      accountData,
    }),
    createAccount: (store, { data }) => functions.httpsCallable('hub_v1_createNewAccount')({
      ...data,
    }),
  },
  getters: {
    // Loaders
    areAccountsLoaded: (state) => !!state.unsubscribeAccounts,

    // Other
    accounts: (state) => state.accounts,
    getAccountByID: (state) => (accountID: string): IAccount | undefined => state.accounts?.find(
      (account) => account.id === accountID,
    ),
  },
}
