<template>
  <div class="LoadingPage">
    <div class="Center">
      <Circular size="medium" />
    </div>
  </div>
</template>

<script>
import Circular from './Progress/Circular.vue'

export default {
  name: 'LoadingPage',
  components: {
    Circular,
  },

}
</script>

<style lang="stylus" scoped>
  .LoadingPage
    position fixed
    top 0
    left 0
    flex-center-children()
    box(100%)
    background-color rgba($color_grey_lightestest, 0.95)
    z-index $loading_page

  .Center
    transform translateY(-50px)
    box(100px)
</style>
