import { baseChecklist } from '@/router/checklist'

export const prices = [

  {
    name: 'Prices',
    path: 'prices',
    meta: {
      checklist: baseChecklist,
      requiresAdmin: true,
    },
    component: () => import(/* webpackChunkName: "sync-to-dev" */ '../../views/Admin/Prices.vue'),
  },

]
