
import { ObjPairBoolean } from '@/types/general'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Circular',
  props: {
    size: {
      type: String,
      required: false,
      default: 'small', // 'tiny' | 'small' | 'medium'
    },
  },
  computed: {
    rootClasses(): ObjPairBoolean {
      return {
        SizeTiny: this.size === 'tiny',
        SizeSmall: this.size === 'small',
        SizeMedium: this.size === 'medium',
      }
    },
  },

})
