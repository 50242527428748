interface IItemV3Constructor {
  id: string
  translation?: string
  itemGroupID?: string
}

export class ItemV3 {
  id: string
  translation: string
  itemGroupID: string

  constructor({
    id,
    translation,
    itemGroupID,
  }: IItemV3Constructor) {
    this.id = id
    this.translation = translation ?? ''
    this.itemGroupID = itemGroupID ?? ''
  }
}
