export interface ITagV2 {
  id: string
  translation: string
}

export class TagV2 implements ITagV2 {
  id: string
  translation: string

  constructor({
    id,
    translation,
  }: ITagV2) {
    this.id = id
    this.translation = translation || ''
  }

  static newInstance(id: string): ITagV2 {
    return new TagV2({
      id,
      translation: '',
    })
  }
}
