/* eslint-disable @typescript-eslint/no-explicit-any */
import { mixWB } from '@/globals/javascript/utils/mixins'
import { COLLECTIONS, db, firebase } from '@/firebase'
import { CategoryV2, ICategoryV2 } from '@/globals/javascript/models/resources-version-2/CategoryV2'
import { CategoryTypeV2, ICategoryTypeV2 } from '@/globals/javascript/models/resources-version-2/CategoryTypeV2'
import { CategoryTypeGroupV2, ICategoryTypeGroupV2 } from '@/globals/javascript/models/resources-version-2/CategoryTypeGroupV2'
import { FractionV2, IFractionV2 } from '@/globals/javascript/models/resources-version-2/FractionV2'
import { GroupQuestionV2, IGroupQuestionV2 } from '@/globals/javascript/models/resources-version-2/GroupQuestionV2'
import { IMaterialV2, MaterialV2 } from '@/globals/javascript/models/resources-version-2/MaterialV2'
import { IQuestionV2, QuestionV2 } from '@/globals/javascript/models/resources-version-2/QuestionV2'
import { ITagV2 } from '@/globals/javascript/models/resources-version-2/TagV2'
import { ITypeV2, TypeV2 } from '@/globals/javascript/models/resources-version-2/TypeV2'
import { FunctionVoid } from '@/types/general'
import {
  IAnalysisDeliveryTimeOptionV2,
  IAnalysisTestV2,
  IAnalysisTestGroupV2,
  IAreaV2, ICoatingTypeV2, IEWCCodeV2, ILaboratoryV2, ISampleTypeV2, IWDGV2,
} from '@/types/resources-version-2'
import { Module } from 'vuex'
import { sortBy } from 'lodash-es'
import { IRootState } from '.'

interface IResourceV2State {
  areas: {
    data: IAreaV2[]
  }
  sampleTypes: {
    data: ISampleTypeV2[]
  }
  analysisTests: {
    data: IAnalysisTestV2[]
  }
  analysisTestGroups: {
    data: IAnalysisTestGroupV2[]
  }
  laboratories: {
    data: ILaboratoryV2[]
  }
  analysisDeliveryTimeOptions: {
    data: IAnalysisDeliveryTimeOptionV2[]
  }
  ecoToxAnalysisTestIDList: {
    data: string[]
  }
  workDescriptionGroups: {
    data: IWDGV2[]
  }
  ewcCodes: {
    data: IEWCCodeV2[]
  }
  categories: {
    idPrefix: string
    nextID: number
    data: ICategoryV2[]
  }
  types: {
    idPrefix: string
    nextID: number
    data: ITypeV2[]
  }
  categoryTypes: {
    idPrefix: string
    nextID: number
    data: ICategoryTypeV2[]
  }
  materials: {
    idPrefix: string
    nextID: number
    data: IMaterialV2[]
  }
  fractions: {
    idPrefix: string
    nextID: number
    data: IFractionV2[]
  }
  tags: {
    idPrefix: string
    nextID: number
    data: ITagV2[]
  }
  questions: {
    idPrefix: string
    nextID: number
    data: IQuestionV2[]
  }
  groupQuestions: {
    idPrefix: string
    nextID: number
    data: IGroupQuestionV2[]
  }
  categoryTypeGroups: {
    idPrefix: string
    nextID: number
    data: ICategoryTypeGroupV2[]
  }
  coatingTypes: {
    data: ICoatingTypeV2[],
  }

  unsubscribeCategoriesV2?: FunctionVoid
  unsubscribeTypesV2?: FunctionVoid
  unsubscribeCategoryTypesV2?: FunctionVoid
  unsubscribeMaterialsV2?: FunctionVoid
  unsubscribeFractionsV2?: FunctionVoid
  unsubscribeTagsV2?: FunctionVoid
  unsubscribeQuestionsV2?: FunctionVoid
  unsubscribeGroupQuestionsV2?: FunctionVoid
  unsubscribeCategoryTypeGroupsV2?: FunctionVoid
  unsubscribeCoatingTypesV2?: FunctionVoid
}

export const resourcesStoreV2: Module<IResourceV2State, IRootState> = {
  state: {
    areas: {
      data: [],
    },
    sampleTypes: {
      data: [],
    },
    analysisTests: {
      data: [],
    },
    analysisTestGroups: {
      data: [],
    },
    laboratories: {
      data: [],
    },
    analysisDeliveryTimeOptions: {
      data: [],
    },
    ecoToxAnalysisTestIDList: {
      data: [],
    },
    workDescriptionGroups: {
      data: [],
    },
    ewcCodes: {
      data: [],
    },
    categories: {
      idPrefix: '',
      nextID: 0,
      data: [],
    },
    types: {
      idPrefix: '',
      nextID: 0,
      data: [],
    },
    categoryTypes: {
      idPrefix: '',
      nextID: 0,
      data: [],
    },
    materials: {
      idPrefix: '',
      nextID: 0,
      data: [],
    },
    fractions: {
      idPrefix: '',
      nextID: 0,
      data: [],
    },
    tags: {
      idPrefix: '',
      nextID: 0,
      data: [],
    },
    questions: {
      idPrefix: '',
      nextID: 0,
      data: [],
    },
    groupQuestions: {
      idPrefix: '',
      nextID: 0,
      data: [],
    },
    categoryTypeGroups: {
      idPrefix: '',
      nextID: 0,
      data: [],
    },
    coatingTypes: {
      data: [],
    },

    // Unsubscribers
    unsubscribeCategoriesV2: undefined,
    unsubscribeTypesV2: undefined,
    unsubscribeCategoryTypesV2: undefined,
    unsubscribeMaterialsV2: undefined,
    unsubscribeFractionsV2: undefined,
    unsubscribeTagsV2: undefined,
    unsubscribeQuestionsV2: undefined,
    unsubscribeGroupQuestionsV2: undefined,
    unsubscribeCategoryTypeGroupsV2: undefined,
    unsubscribeCoatingTypesV2: undefined,
  },
  mutations: {
    updateStateV2: (state, {
      propName,
      propData,
      unsubscribeName,
      unsubscribe,
    }: {
      propName: string,
      propData: any,
      unsubscribeName?: string,
      unsubscribe?: FunctionVoid,
     }) => {
      if (propName in (state as any)) {
        (state as any)[propName] = propData
      }

      if (
        unsubscribeName
        && unsubscribeName in (state as any)
        && !(state as any)[unsubscribeName]
      ) {
        (state as any)[unsubscribeName] = unsubscribe
      }
    },
    resetResourcesV2: (state) => {
      state.areas.data = []
      state.categories.data = []

      if (state.unsubscribeCategoriesV2) {
        state.unsubscribeCategoriesV2()
        state.unsubscribeCategoriesV2 = undefined
      }
    },
  },
  actions: {
    // Getters
    async getAreasV2({ commit }) {
      const snapshot = await db
        .collection(COLLECTIONS.DB_RESOURCES_V2)
        .doc('-areas-')
        .get()

      commit('updateStateV2', {
        propName: 'areas',
        propData: snapshot.data(),
      })
    },
    async getSampleTypesV2({ commit }) {
      const snapshot = await db
        .collection(COLLECTIONS.DB_RESOURCES_V2)
        .doc('-sample-types-')
        .get()

      commit('updateStateV2', {
        propName: 'sampleTypes',
        propData: snapshot.data(),
      })
    },
    async getAnalysisTestsV2({ commit }) {
      const snapshot = await db
        .collection(COLLECTIONS.DB_RESOURCES_V2)
        .doc('-analysis-tests-')
        .get()

      commit('updateStateV2', {
        propName: 'analysisTests',
        propData: snapshot.data(),
      })
    },
    async getAnalysisTestGroupsV2({ commit }) {
      const snapshot = await db
        .collection(COLLECTIONS.DB_RESOURCES_V2)
        .doc('-analysis-test-groups-')
        .get()

      commit('updateStateV2', {
        propName: 'analysisTestGroups',
        propData: snapshot.data(),
      })
    },
    async getLaboratoriesV2({ commit }) {
      const snapshot = await db
        .collection(COLLECTIONS.DB_RESOURCES_V2)
        .doc('-laboratories-')
        .get()

      commit('updateStateV2', {
        propName: 'laboratories',
        propData: snapshot.data(),
      })
    },
    async getAnalysisDeliveryTimeOptionsV2({ commit }) {
      const snapshot = await db
        .collection(COLLECTIONS.DB_RESOURCES_V2)
        .doc('-analysis-delivery-time-options-')
        .get()

      commit('updateStateV2', {
        propName: 'analysisDeliveryTimeOptions',
        propData: snapshot.data(),
      })
    },
    async getEcoToxAnalysisTestIDListV2({ commit }) {
      const snapshot = await db
        .collection(COLLECTIONS.DB_RESOURCES_V2)
        .doc('-eco-tox-analysis-test-id-list-')
        .get()

      commit('updateStateV2', {
        propName: 'ecoToxAnalysisTestIDList',
        propData: snapshot.data(),
      })
    },
    async getWorkDescriptionGroupsV2({ commit }) {
      const snapshot = await db
        .collection(COLLECTIONS.DB_RESOURCES_V2)
        .doc('-work-description-groups-')
        .get()

      commit('updateStateV2', {
        propName: 'workDescriptionGroups',
        propData: snapshot.data(),
      })
    },
    async getEWCCodesV2({ commit }) {
      const snapshot = await db
        .collection(COLLECTIONS.DB_RESOURCES_V2)
        .doc('-ewc-codes-')
        .get()

      commit('updateStateV2', {
        propName: 'ewcCodes',
        propData: snapshot.data(),
      })
    },
    getCategoriesV2: ({ getters, commit }) => {
      if (getters.areCategoriesLoadedV2) {
        return
      }

      const unsubscribe = db
        .collection(COLLECTIONS.DB_RESOURCES_V2)
        .doc('-categories-')
        .onSnapshot((doc) => {
          const docData = doc.data() || {}
          const data = {
            ...docData,
            data: [] as ICategoryV2[],
          }

          docData.data.forEach((category: ICategoryV2) => {
            data.data.push(new CategoryV2(category))
          })

          commit('updateStateV2', {
            propName: 'categories',
            propData: data,
            unsubscribeName: 'unsubscribeCategoriesV2',
            unsubscribe,
          })
        })
    },
    getTypesV2: ({ getters, commit }) => {
      if (getters.areTypesLoadedV2) {
        return
      }

      const unsubscribe = db
        .collection(COLLECTIONS.DB_RESOURCES_V2)
        .doc('-types-')
        .onSnapshot((doc) => {
          const docData = doc.data() || {}
          const data = {
            ...docData,
            data: [] as ITypeV2[],
          }

          docData.data.forEach((type: ITypeV2) => {
            data.data.push(new TypeV2(type))
          })

          commit('updateStateV2', {
            propName: 'types',
            propData: data,
            unsubscribeName: 'unsubscribeTypesV2',
            unsubscribe,
          })
        })
    },
    getCategoryTypesV2: ({ getters, commit }) => {
      if (getters.areCategoryTypesLoadedV2) {
        return
      }

      const unsubscribe = db
        .collection(COLLECTIONS.DB_RESOURCES_V2)
        .doc('-category-types-')
        .onSnapshot((doc) => {
          const docData = doc.data() || {}
          const data = {
            ...docData,
            data: [] as ICategoryTypeV2[],
          }

          docData.data.forEach((categoryType: ICategoryTypeV2) => {
            data.data.push(new CategoryTypeV2(categoryType))
          })

          commit('updateStateV2', {
            propName: 'categoryTypes',
            propData: data,
            unsubscribeName: 'unsubscribeCategoryTypesV2',
            unsubscribe,
          })
        })
    },
    getMaterialsV2: ({ getters, commit }) => {
      if (getters.areMaterialsLoadedV2) {
        return
      }

      const unsubscribe = db
        .collection(COLLECTIONS.DB_RESOURCES_V2)
        .doc('-materials-')
        .onSnapshot((doc) => {
          const docData = doc.data() || {}
          const data = {
            ...docData,
            data: [] as IMaterialV2[],
          }

          docData.data.forEach((material: IMaterialV2) => {
            data.data.push(new MaterialV2(material))
          })

          commit('updateStateV2', {
            propName: 'materials',
            propData: data,
            unsubscribeName: 'unsubscribeMaterialsV2',
            unsubscribe,
          })
        })
    },
    getFractionsV2: ({ getters, commit }) => {
      if (getters.areFractionsLoadedV2) {
        return
      }

      const unsubscribe = db
        .collection(COLLECTIONS.DB_RESOURCES_V2)
        .doc('-fractions-')
        .onSnapshot((doc) => {
          const docData = doc.data() || {}
          const data = {
            ...docData,
            data: [] as IFractionV2[],
          }

          docData.data.forEach((fraction: IFractionV2) => {
            data.data.push(new FractionV2(fraction))
          })

          commit('updateStateV2', {
            propName: 'fractions',
            propData: data,
            unsubscribeName: 'unsubscribeFractionsV2',
            unsubscribe,
          })
        })
    },
    getTagsV2: ({ getters, commit }) => {
      if (getters.areTagsLoadedV2) {
        return
      }

      const unsubscribe = db
        .collection(COLLECTIONS.DB_RESOURCES_V2)
        .doc('-tags-')
        .onSnapshot((doc) => {
          commit('updateStateV2', {
            propName: 'tags',
            propData: doc.data(),
            unsubscribeName: 'unsubscribeTagsV2',
            unsubscribe,
          })
        })
    },
    getQuestionsV2: ({ getters, commit }) => {
      if (getters.areQuestionsLoadedV2) {
        return
      }

      const unsubscribe = db
        .collection(COLLECTIONS.DB_RESOURCES_V2)
        .doc('-questions-')
        .onSnapshot((doc) => {
          const docData = doc.data() || {}
          const data = {
            ...docData,
            data: [] as IQuestionV2[],
          }

          docData.data.forEach((question: IQuestionV2) => {
            data.data.push(new QuestionV2(question))
          })

          commit('updateStateV2', {
            propName: 'questions',
            propData: data,
            unsubscribeName: 'unsubscribeQuestionsV2',
            unsubscribe,
          })
        })
    },
    getGroupQuestionsV2: ({ getters, commit }) => {
      if (getters.areGroupQuestionsLoadedV2) {
        return
      }

      const unsubscribe = db
        .collection(COLLECTIONS.DB_RESOURCES_V2)
        .doc('-group-questions-')
        .onSnapshot((doc) => {
          const docData = doc.data() || {}
          const data = {
            ...docData,
            data: [] as IGroupQuestionV2[],
          }

          docData.data.forEach((groupQuestion: IGroupQuestionV2) => {
            data.data.push(new GroupQuestionV2(groupQuestion))
          })

          commit('updateStateV2', {
            propName: 'groupQuestions',
            propData: data,
            unsubscribeName: 'unsubscribeGroupQuestionsV2',
            unsubscribe,
          })
        })
    },
    getCategoryTypeGroupsV2: ({ getters, commit }) => {
      if (getters.areCategoryTypeGroupsLoadedV2) {
        return
      }

      const unsubscribe = db
        .collection(COLLECTIONS.DB_RESOURCES_V2)
        .doc('-category-type-groups-')
        .onSnapshot((doc) => {
          const docData = doc.data() || {}
          const data = {
            ...docData,
            data: [] as ICategoryTypeGroupV2[],
          }

          docData.data.forEach((categoryTypeGroup: ICategoryTypeGroupV2) => {
            data.data.push(new CategoryTypeGroupV2(categoryTypeGroup))
          })

          commit('updateStateV2', {
            propName: 'categoryTypeGroups',
            propData: data,
            unsubscribeName: 'unsubscribeCategoryTypeGroupsV2',
            unsubscribe,
          })
        })
    },
    async getCoatingTypesV2({ commit }) {
      const snapshot = await db
        .collection(COLLECTIONS.DB_RESOURCES_V2)
        .doc('-coating-types-')
        .get()

      commit('updateStateV2', {
        propName: 'coatingTypes',
        propData: snapshot.data(),
      })
    },

    // Setters
    setResourceV2: (store, {
      doc,
      data,
      shouldBumpID = false,
    }) => {
      db
        .collection(COLLECTIONS.DB_RESOURCES_V2)
        .doc(doc)
        .set({
          nextID: firebase.firestore.FieldValue.increment(shouldBumpID ? 1 : 0),
          data,
        }, { merge: true })
    },
  },
  getters: {
    // Loaders
    areAreasLoadedV2: (state) => !!state.areas.data.length,
    areSampleTypesLoadedV2: (state) => !!state.sampleTypes.data.length,
    areAnalysisTestsLoadedV2: (state) => !!state.analysisTests.data.length,
    areAnalysisTestGroupsLoadedV2: (state) => !!state.analysisTestGroups.data.length,
    areLaboratoriesLoadedV2: (state) => !!state.laboratories.data.length,
    areAnalysisDeliveryTimeOptionsLoadedV2: (
      state,
    ) => !!state.analysisDeliveryTimeOptions.data.length,
    areEcoToxListLoadedV2: (state) => !!state.ecoToxAnalysisTestIDList.data.length,
    areWorkDescrioptionGroupsLoadedV2: (state) => !!state.workDescriptionGroups.data.length,
    areEWCCodesLoadedV2: (state) => !!state.ewcCodes.data.length,
    areCoatingTypesLoadedV2: (state) => !!state.coatingTypes.data.length,
    areCategoriesLoadedV2: (state) => !!state.unsubscribeCategoriesV2,
    areTypesLoadedV2: (state) => !!state.unsubscribeTypesV2,
    areCategoryTypesLoadedV2: (state) => !!state.unsubscribeCategoryTypesV2,
    areMaterialsLoadedV2: (state) => !!state.unsubscribeMaterialsV2,
    areFractionsLoadedV2: (state) => !!state.unsubscribeFractionsV2,
    areTagsLoadedV2: (state) => !!state.unsubscribeTagsV2,
    areQuestionsLoadedV2: (state) => !!state.unsubscribeQuestionsV2,
    areGroupQuestionsLoadedV2: (state) => !!state.unsubscribeGroupQuestionsV2,
    areCategoryTypeGroupsLoadedV2: (state) => !!state.unsubscribeCategoryTypeGroupsV2,

    // Other
    areasAsArrayV2: (state) => state.areas.data,
    sampleTypesAsArrayV2: (state) => state.sampleTypes.data,
    analysisTestsAsArrayV2: (state) => state.analysisTests.data,
    analysisTestGroupsAsArrayV2: (state) => state.analysisTestGroups.data,
    laboratoriesAsArrayV2: (state) => state.laboratories.data,
    analysisDeliveryTimeOptionsAsArrayV2: (state) => state.analysisDeliveryTimeOptions.data,
    ecoToxAnalysisTestIDListAsArrayV2: (state) => state.ecoToxAnalysisTestIDList.data,
    workDescriptionGroupsAsArrayV2: (state) => state.workDescriptionGroups.data,
    ewcCodesAsArrayV2: (state) => state.ewcCodes.data,
    coatingTypesAsArrayV2: (state) => state.coatingTypes.data,

    categoriesAsArrayV2: (state) => state.categories.data,
    nextCategoryIDV2: (state, getters) => {
      if (!getters.areCategoriesLoadedV2) {
        return ''
      }
      return state.categories.idPrefix + state.categories.nextID
    },

    typesAsArrayV2: (state) => state.types.data,
    nextTypeIDV2: (state, getters) => {
      if (!getters.areTypesLoadedV2) {
        return ''
      }
      return state.types.idPrefix + state.types.nextID
    },

    categoryTypesAsArrayV2: (state) => state.categoryTypes.data,
    nextCategoryTypeIDV2: (state, getters) => {
      if (!getters.areCategoryTypesLoadedV2) {
        return ''
      }
      return state.categoryTypes.idPrefix + state.categoryTypes.nextID
    },

    materialsAsArrayV2: (state) => state.materials.data,
    nextMaterialIDV2: (state, getters) => {
      if (!getters.areMaterialsLoadedV2) {
        return ''
      }
      return state.materials.idPrefix + state.materials.nextID
    },

    fractionsAsArrayV2: (state) => state.fractions.data,
    nextFractionIDV2: (state, getters) => {
      if (!getters.areFractionsLoadedV2) {
        return ''
      }
      return state.fractions.idPrefix + state.fractions.nextID
    },

    tagsAsArrayV2: (state) => state.tags.data,
    nextTagIDV2: (state, getters) => {
      if (!getters.areTagsLoadedV2) {
        return ''
      }
      return state.tags.idPrefix + state.tags.nextID
    },

    questionsAsArrayV2: (state) => state.questions.data,
    nextQuestionIDV2: (state, getters) => {
      if (!getters.areQuestionsLoadedV2) {
        return ''
      }
      return state.questions.idPrefix + state.questions.nextID
    },

    groupQuestionsAsArrayV2: (state) => state.groupQuestions.data,
    nextGroupQuestionIDV2: (state, getters) => {
      if (!getters.areGroupQuestionsLoadedV2) {
        return ''
      }
      return state.groupQuestions.idPrefix + state.groupQuestions.nextID
    },

    categoryTypeGroupsAsArrayV2: (state) => state.categoryTypeGroups.data,
    nextCategoryTypeGroupIDV2: (state, getters) => {
      if (!getters.areCategoryTypeGroupsLoadedV2) {
        return ''
      }
      return state.categoryTypeGroups.idPrefix + state.categoryTypeGroups.nextID
    },
    kasperTest: (state, getters) => {
      const {
        categoriesAsArrayV2,
        categoryTypesAsArrayV2,
        typesAsArrayV2,
        workDescriptionGroupsAsArrayV2,
        materialsAsArrayV2,
      } = getters

      const intoCategories = categoryTypesAsArrayV2.reduce(
        (prev: any, categoryType: ICategoryTypeV2) => {
          if (!prev[categoryType.categoryID]) {
            prev[categoryType.categoryID] = []
          }

          prev[categoryType.categoryID].push(categoryType)
          return prev
        }, {},
      )

      const otherAmount = categoryTypesAsArrayV2.filter((categoryType: ICategoryTypeV2) => {
        const amountData = Object.values(categoryType.materialData)[0]
        const { units } = amountData

        return Object.entries(units).some(([key, value]) => value.status !== 1)
      })

      const otherAmountTitles = sortBy(otherAmount.map((categoryType: ICategoryTypeV2) => {
        const category: ICategoryV2 = categoriesAsArrayV2.find(
          (x: ICategoryV2) => x.id === categoryType.categoryID,
        )
        const type: ITypeV2 = typesAsArrayV2.find((x: ITypeV2) => x.id === categoryType.typeID)

        return {
          category: mixWB(category.translation),
          type: mixWB(type.translation),
        }
      }), ['category'])

      const typesToWDGs = workDescriptionGroupsAsArrayV2.reduce((prev: any, wdg: IWDGV2) => {
        prev[wdg.id] = {
          title: mixWB(wdg.translation),
          types: typesAsArrayV2.filter((x: ITypeV2) => x.wdgID === wdg.id),
        }

        return prev
      }, {})

      const typesWithFractionDiff = typesAsArrayV2.filter((type: ITypeV2) => {
        const { materialData } = type
        const { fractions } = Object.values(materialData)[0].fractions

        let isGood = false
        Object.entries(fractions).forEach(([key, value]) => {
          if (value.ewcID || value.fractionID) {
            isGood = true
          }
        })

        return isGood
      })

      const toEbbe = sortBy(typesAsArrayV2.map((type: ITypeV2) => {
        const data = {
          title: '',
          material: '',
        }

        const material = materialsAsArrayV2.find(
          (x: IMaterialV2) => x.id === type.materialIDs[0],
        )

        data.title = mixWB(type.translation)
        data.material = mixWB(material?.translation)

        return data
      }), ['title'])

      return 'hest'
    },
  },
}
