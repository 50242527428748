import store from '@/store'
import { TUser } from '@/types'
import { FracionTypesV3, ILogV3 } from '@/types/resources-version-3'

interface IFractionV3Constructor {
  id: string
  translation?: string
  types?: FracionTypesV3[]
  avgPrice?: number
  logs?: ILogV3[]
}

export class FractionV3 {
  id: string
  translation: string
  types: FracionTypesV3[]
  avgPrice: number
  logs: ILogV3[]

  constructor({
    id,
    translation,
    types,
    avgPrice,
    logs,
  }: IFractionV3Constructor) {
    this.id = id
    this.translation = translation ?? ''
    this.types = types ?? []
    this.avgPrice = avgPrice ?? 0
    this.logs = logs ?? []
  }

  getUser(): TUser | undefined {
    if (!this.logs.length) {
      return undefined
    }

    const { getUserByID } = store.getters

    return getUserByID(this.logs[this.logs.length - 1].userID)
  }
}
