export interface ILogChangeV3 {
  key: string
  newValue?: string
  oldValue?: string
  type?: string
}

export interface ILogV3 {
  userID: string
  timestamp: number
  changes: ILogChangeV3[]
}

export enum FracionTypesV3 {
  CLEAN = 'clean',
  CONTAMINATED = 'contaminated',
  OTHER = 'other',
}

export interface IEWCCodeV3 {
  id: string
  translation: string
}

export interface IAreaV3 {
  id: string
  order: number
  translation: string
}

export interface IItemGroupV3 {
  id: string
  translation: string
}

export interface ILaboratoryV3 {
  id: string
  translation: string
  phone: string
  requisitionEmail: string
  isActive: boolean
  excelFileColumnNames: {
    analysisID: string
    columnName: string
  }[]
  requisition: {
    sampleTypeID: string
    text: string
    code: string
  }[]
}

export interface IAnalysisDeliveryTimeOptionV3 {
  id: string
  order: number
  days: number
  hours: number
  stanlabID: string | null
}

export interface ISampleTypeV3 {
  id: string
  order: number
  translation: string
  analysisTestGroupID: string
  stanlabID: string | null
}

export interface IAnalysisTestGroupV3 {
  id: string
  order: number
  sampleTypeID: string
  translation: string
  analysisTestIDs: string[]
}

export interface IAnalysisTestV3 {
  id: string
  maxValue: number
  minValue: number
  translation: string
  type: string
  stancodeID: number | null
  unit: string
}

export interface IColorV3 {
  id: string
  translation: string
  position: number
  colorCode: string
  options: {
    isActive: boolean
  }
}

export interface IProjectTypeV3 {
  id: string
  translation: string
  position: number
  options: {
    isActive: boolean
    isScreening: boolean
  }
}

export interface IProjectOptionV3 {
  id: string
  translation: string
  options: {
    isActive: boolean
  }
}
