import store from '@/store'
import { HOST } from '@/globals/javascript/utils/host'

export const mixins = {
  methods: {
    mixWB(key: string, params: string[] = []) {
      const {
        translations,
      } = store.getters

      if (!key) {
        return ''
      }

      const translation = translations[key] || false

      let textToUse = ''

      if (!translation) {
        textToUse = key.replace('W: ', '')
      }
      else {
        textToUse = translation['da-DK']
      }

      params.forEach((param) => {
        textToUse = textToUse.replace('%s', param)
      })

      return textToUse
    },
    mixGetFileUrl({
      path,
      filename,
    }: {
        path: string,
        filename: string,
      }) {
      return `${
        HOST.cloudFunction
      }/system_proxy_cloud_storage?path=${
        path
      }&filename=${
        filename
      }`
    },
  },
}

export const { mixWB, mixGetFileUrl } = mixins.methods
