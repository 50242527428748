interface ICategoryV3Constructor {
  id: string
  position?: number
  areaIDs?: string[]
  translation?: string
  connectedMaterialIDs?: string[]
  connectedTypeIDs?: string[]
  suggestedTypeIDs?: string[]
  suggestedTypeGroupIDs?: string[]
  options?: {
    isActive: boolean
    isArchived: boolean
    useItems: boolean
  }
}

export class CategoryV3 {
  id: string
  position: number
  areaIDs: string[]
  translation: string
  connectedMaterialIDs: string[]
  connectedTypeIDs: string[]
  suggestedTypeIDs: string[]
  suggestedTypeGroupIDs: string[]
  options: {
    isActive: boolean
    isArchived: boolean
    useItems: boolean
  }

  constructor({
    id,
    position,
    areaIDs,
    translation,
    connectedMaterialIDs,
    connectedTypeIDs,
    suggestedTypeIDs,
    suggestedTypeGroupIDs,
    options,
  }: ICategoryV3Constructor) {
    this.id = id
    this.position = position ?? 0
    this.areaIDs = areaIDs || []
    this.translation = translation ?? ''
    this.connectedMaterialIDs = connectedMaterialIDs ?? []
    this.connectedTypeIDs = connectedTypeIDs ?? []
    this.suggestedTypeIDs = suggestedTypeIDs ?? []
    this.suggestedTypeGroupIDs = suggestedTypeGroupIDs ?? []
    this.options = {
      isActive: options?.isActive ?? false,
      isArchived: options?.isArchived ?? false,
      useItems: options?.useItems ?? false,
    }
  }
}
