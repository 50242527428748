import { ITypeV2, ITypeQuestionV2 } from '@/globals/javascript/models/resources-version-2/TypeV2'
import store from '@/store'

interface IGroupQuestionConstructorV2 {
  id: string
  translation: string
  questions: ITypeQuestionV2[]
}

export interface IGroupQuestionV2 extends IGroupQuestionConstructorV2 {
  getNoOfTypes(): number
}

export class GroupQuestionV2 implements IGroupQuestionV2 {
  id: string
  translation: string
  questions: ITypeQuestionV2[]

  constructor({
    id,
    translation,
    questions,
  }: IGroupQuestionConstructorV2) {
    this.id = id
    this.translation = translation || ''
    this.questions = questions || []
  }

  getNoOfTypes(): number {
    let count = 0
    const { typesAsArrayV2 } = store.getters

    typesAsArrayV2.forEach((type: ITypeV2) => {
      const isUsingQuestion = type.questions.find((x: ITypeQuestionV2) => x.questionID === this.id)
      if (isUsingQuestion) {
        count += 1
      }
    })

    return count
  }

  static newInstance(id: string): IGroupQuestionV2 {
    return new GroupQuestionV2({
      id,
      translation: '',
      questions: [],
    })
  }
}
