/* eslint-disable @typescript-eslint/no-explicit-any */
import { COLLECTIONS, db } from '@/firebase'
import { CustomRoomV2, ICustomRoomV2 } from '@/globals/javascript/models/resources-version-2/CustomRoomV2'
import { CustomTypeV2, ICustomTypeV2 } from '@/globals/javascript/models/resources-version-2/CustomTypeV2'
import { FunctionVoid } from '@/types/general'
import { Module } from 'vuex'
import { IRootState } from '.'

interface IReportsV2State {
  customTypes: ICustomTypeV2[],
  reusedTypes: ICustomTypeV2[],
  customRooms: ICustomRoomV2[],

  unsubscribeCustomTypesV2?: FunctionVoid
  unsubscribeReusedTypesV2?: FunctionVoid
  unsubscribeCustomRoomsV2?: FunctionVoid
}

export const reportsStoreV2: Module<IReportsV2State, IRootState> = {
  state: {
    customTypes: [],
    reusedTypes: [],
    customRooms: [],

    // Unsubscribers
    unsubscribeCustomTypesV2: undefined,
    unsubscribeReusedTypesV2: undefined,
    unsubscribeCustomRoomsV2: undefined,
  },
  mutations: {
    updateCustomTypesV2: (state, { customTypes, unsubscribe }) => {
      state.customTypes = customTypes
      state.unsubscribeCustomTypesV2 = unsubscribe
    },
    updateReusedTypesV2: (state, { reusedTypes, unsubscribe }) => {
      state.reusedTypes = reusedTypes
      state.unsubscribeReusedTypesV2 = unsubscribe
    },
    updateCustomRoomsV2: (state, { customRooms, unsubscribe }) => {
      state.customRooms = customRooms
      state.unsubscribeCustomRoomsV2 = unsubscribe
    },
    resetReportsV2: (state) => {
      state.customTypes = []
      state.reusedTypes = []
      state.customRooms = []

      if (state.unsubscribeCustomTypesV2) {
        state.unsubscribeCustomTypesV2()
        state.unsubscribeCustomTypesV2 = undefined
      }
      if (state.unsubscribeReusedTypesV2) {
        state.unsubscribeReusedTypesV2()
        state.unsubscribeReusedTypesV2 = undefined
      }
      if (state.unsubscribeCustomRoomsV2) {
        state.unsubscribeCustomRoomsV2()
        state.unsubscribeCustomRoomsV2 = undefined
      }
    },
  },
  actions: {
    getCustomTypesV2: ({ commit }) => {
      const unsubscribe = db
        .collection(COLLECTIONS.DB_REPORTS_V2)
        .doc('-custom-types-')
        .onSnapshot((doc) => {
          const docData = doc.data() || {}
          const customTypes = [] as ICustomTypeV2[]

          if (docData?.data?.length) {
            docData.data.forEach((customTypeRaw: ICustomTypeV2) => {
              customTypes.push(new CustomTypeV2(customTypeRaw))
            })
          }

          commit('updateCustomTypesV2', { customTypes, unsubscribe })
        })
    },
    getReusedTypesV2: ({ commit }) => {
      const unsubscribe = db
        .collection(COLLECTIONS.DB_REPORTS_V2)
        .doc('-reused-types-')
        .onSnapshot((doc) => {
          const docData = doc.data() || {}
          const reusedTypes = [] as ICustomTypeV2[]

          if (docData?.data?.length) {
            docData.data.forEach((customTypeRaw: ICustomTypeV2) => {
              reusedTypes.push(new CustomTypeV2(customTypeRaw))
            })
          }

          commit('updateReusedTypesV2', { reusedTypes, unsubscribe })
        })
    },
    getCustomRoomsV2: ({ commit }) => {
      const unsubscribe = db
        .collection(COLLECTIONS.DB_REPORTS_V2)
        .doc('-custom-rooms-')
        .onSnapshot((doc) => {
          const docData = doc.data() || {}
          const customRooms = [] as ICustomRoomV2[]

          if (docData?.data?.length) {
            docData.data.forEach((customTypeRaw: ICustomRoomV2) => {
              customRooms.push(new CustomRoomV2(customTypeRaw))
            })
          }

          commit('updateCustomRoomsV2', { customRooms, unsubscribe })
        })
    },
    deleteCustomTypesV2: ({ getters }, indexes) => {
      const newList = getters.customTypes.reduce((prev: any, x: ICustomTypeV2, index: number) => {
        if (!indexes.includes(index)) {
          prev.push(JSON.parse(JSON.stringify(x)))
        }

        return prev
      }, [])

      db
        .collection(COLLECTIONS.DB_REPORTS_V2)
        .doc('-custom-types-')
        .set({
          data: [...newList],
        }, { merge: true })
    },
    deleteReusedTypesV2: ({ getters }, indexes) => {
      const newList = getters.reusedTypes.reduce((prev: any, x: ICustomTypeV2, index: number) => {
        if (!indexes.includes(index)) {
          prev.push(JSON.parse(JSON.stringify(x)))
        }

        return prev
      }, [])

      db
        .collection(COLLECTIONS.DB_REPORTS_V2)
        .doc('-reused-types-')
        .set({
          data: [...newList],
        }, { merge: true })
    },
    deleteCustomRoomsV2: ({ getters }, indexes) => {
      const newList = getters.customRooms.reduce((prev: any, x: ICustomRoomV2, index: number) => {
        if (!indexes.includes(index)) {
          prev.push(JSON.parse(JSON.stringify(x)))
        }

        return prev
      }, [])

      db
        .collection(COLLECTIONS.DB_REPORTS_V2)
        .doc('-custom-rooms-')
        .set({
          data: [...newList],
        }, { merge: true })
    },
  },
  getters: {
    // Loaders
    areReportsLoadedV2: (state) => !!state.unsubscribeCustomTypesV2,

    // Other
    customTypesV2: (state) => state.customTypes,
    reusedTypesV2: (state) => state.reusedTypes,
    customRoomsV2: (state) => state.customRooms,
  },
}
