import { createApp } from 'vue'
import InlineSvg from 'vue-inline-svg'
import App from './App.vue'
import router from './router'
import store from './store'
import './globals/stylus/reset.styl'
import { mixins } from './globals/javascript/utils/mixins'

const app = createApp({
  extends: App,
  mixins: [mixins],
})

app.use(store).use(router).mixin({ methods: mixins.methods }).mount('#app')
app.component('InlineSVG', InlineSvg)

app.config.performance = true
