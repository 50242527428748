// User typeGuards

import {
  IUserHub, IUserPro, TUser,
} from '@/types'

export function isProUser(user:TUser | undefined):user is IUserPro {
  return !!(user as IUserPro | undefined)?.isActivated
}
export function isHubUser(user:TUser| undefined):user is IUserHub {
  return !!(user as IUserHub | undefined)?.hub?.isActivated
}
