/* eslint-disable @typescript-eslint/no-explicit-any */
import { COLLECTIONS, db } from '@/firebase'
import { IRootState } from '@/store'
import {
  IEWCCodeV2,
} from '@/types/resources-version-2'
import { Module } from 'vuex'
import v3Fractions from '@/globals/resources-version-3-files/v3-fractions.json'
import v3Materials from '@/globals/resources-version-3-files/v3-materials.json'
import v3Items from '@/globals/resources-version-3-files/v3-items.json'
import v3ItemGroups from '@/globals/resources-version-3-files/v3-item-groups.json'
import v3Areas from '@/globals/resources-version-3-files/v3-areas.json'
import v3Categories from '@/globals/resources-version-3-files/v3-categories.json'
import v3Types from '@/globals/resources-version-3-files/v3-types.json'
import v3TypeGroups from '@/globals/resources-version-3-files/v3-type-groups.json'
import v3Rooms from '@/globals/resources-version-3-files/v3-rooms.json'
import v3Tags from '@/globals/resources-version-3-files/v3-tags.json'
import v3Coatings from '@/globals/resources-version-3-files/v3-coatings.json'
import v3CoatingTypes from '@/globals/resources-version-3-files/v3-coating-types.json'
import v3Colors from '@/globals/resources-version-3-files/v3-colors.json'
import v3Questions from '@/globals/resources-version-3-files/v3-questions.json'
import v3GroupQuestions from '@/globals/resources-version-3-files/v3-group-questions.json'
import v3Laboratories from '@/globals/resources-version-3-files/v3-laboratories.json'
import v3AnalysisTestGroups from '@/globals/resources-version-3-files/v3-analysis-test-groups.json'
import v3AnalysisTests from '@/globals/resources-version-3-files/v3-analysis-tests.json'
import v3ProjectTypes from '@/globals/resources-version-3-files/v3-project-types.json'
import v3ProjectOptions from '@/globals/resources-version-3-files/v3-project-options.json'
import add6MetalsSampleTypes from '@/globals/other-files/add-6-metals/sample-types.json'
import add6MetalsAnalysesTestGroups from '@/globals/other-files/add-6-metals/analyses-groups.json'
import addLyeCoatings from '@/globals/other-files/add-lye/add-lye-coatings.json'
import addLyeCoatingTypes from '@/globals/other-files/add-lye/add-lye-coating-types.json'
import { copyObject } from '@/globals/javascript/utils/helpers'
import { IAnalysisTestGroupV3, IAnalysisTestV3, ILaboratoryV3 } from '@/types/resources-version-3'
import { clone } from 'lodash-es'

export const transferV2ToV3Resources: Module<any, IRootState> = {
  actions: {
    // Main update
    transferData: (store, {
      docID,
      data,
      idPrefix,
      nextID,
    }) => {
      const resourcesData: {data: any, idPrefix?: string, nextID?: number} = {
        data,
      }

      if (idPrefix) {
        resourcesData.idPrefix = idPrefix
      }
      if (nextID) {
        resourcesData.nextID = nextID
      }

      db
        .collection(COLLECTIONS.DB_RESOURCES_V3)
        .doc(docID)
        .set(copyObject(resourcesData))
    },

    // Transfers
    transferV2EWCCodes: ({ dispatch, getters }) => {
      const {
        ewcCodesAsArrayV2,
      } = getters

      const docID = '-ewc-codes-'

      const data: IEWCCodeV2[] = JSON.parse(JSON.stringify(ewcCodesAsArrayV2))

      dispatch('transferData', {
        docID,
        data,
      })
    },

    // Upload V3 data
    uploadV3Areas: ({ dispatch }) => {
      const data = v3Areas
      const docID = '-areas-'

      dispatch('transferData', {
        docID,
        data,
      })
    },
    uploadV3Categories: ({ dispatch }) => {
      const { data, nextID, idPrefix } = v3Categories

      const docID = '-categories-'

      dispatch('transferData', {
        docID,
        data,
        idPrefix,
        nextID,
      })
    },
    uploadV3Types: ({ dispatch }) => {
      const { data, nextID, idPrefix } = v3Types

      const docID = '-types-'

      dispatch('transferData', {
        docID,
        data,
        idPrefix,
        nextID,
      })
    },
    uploadV3TypeGroups: ({ dispatch }) => {
      const { data, nextID, idPrefix } = v3TypeGroups

      const docID = '-type-groups-'

      dispatch('transferData', {
        docID,
        data,
        idPrefix,
        nextID,
      })
    },
    uploadV3Rooms: ({ dispatch }) => {
      const { data, nextID, idPrefix } = v3Rooms

      const docID = '-rooms-'

      dispatch('transferData', {
        docID,
        data,
        idPrefix,
        nextID,
      })
    },
    uploadV3Tags: ({ dispatch }) => {
      const { data, nextID, idPrefix } = v3Tags

      const docID = '-tags-'

      dispatch('transferData', {
        docID,
        data,
        idPrefix,
        nextID,
      })
    },
    uploadV3Coatings: ({ dispatch }) => {
      const { data, nextID, idPrefix } = v3Coatings

      const docID = '-coatings-'

      dispatch('transferData', {
        docID,
        data,
        idPrefix,
        nextID,
      })
    },
    uploadV3CoatingTypes: ({ dispatch }) => {
      const { data, nextID, idPrefix } = v3CoatingTypes

      const docID = '-coating-types-'

      dispatch('transferData', {
        docID,
        data,
        idPrefix,
        nextID,
      })
    },
    uploadV3Colors: ({ dispatch }) => {
      const { data, nextID, idPrefix } = v3Colors

      const docID = '-colors-'

      dispatch('transferData', {
        docID,
        data,
        idPrefix,
        nextID,
      })
    },
    uploadV3Questions: ({ dispatch }) => {
      const { data, nextID, idPrefix } = v3Questions

      const docID = '-questions-'

      dispatch('transferData', {
        docID,
        data,
        idPrefix,
        nextID,
      })
    },
    uploadV3GroupQuestions: ({ dispatch }) => {
      const { data, nextID, idPrefix } = v3GroupQuestions

      const docID = '-group-questions-'

      dispatch('transferData', {
        docID,
        data,
        idPrefix,
        nextID,
      })
    },

    uploadV3Fractions: ({ dispatch }) => {
      const { data, nextID, idPrefix } = v3Fractions

      const docID = '-fractions-'

      dispatch('transferData', {
        docID,
        data,
        idPrefix,
        nextID,
      })
    },
    uploadV3Materials: ({ dispatch }) => {
      const { data, nextID, idPrefix } = v3Materials

      const docID = '-materials-'

      dispatch('transferData', {
        docID,
        data,
        idPrefix,
        nextID,
      })
    },
    uploadV3Items: ({ dispatch }) => {
      const { data, nextID, idPrefix } = v3Items

      const docID = '-items-'

      dispatch('transferData', {
        docID,
        data,
        idPrefix,
        nextID,
      })
    },
    uploadV3ItemGroups: ({ dispatch }) => {
      const { data } = v3ItemGroups
      const docID = '-item-groups-'

      dispatch('transferData', {
        docID,
        data,
      })
    },
    uploadV3Laboratories: ({ dispatch }) => {
      const { data } = v3Laboratories
      const docID = '-laboratories-'

      dispatch('transferData', {
        docID,
        data,
      })
    },
    uploadV3AnalysisTestGroups: ({ dispatch }) => {
      const { data } = v3AnalysisTestGroups
      const docID = '-analysis-test-groups-'

      dispatch('transferData', {
        docID,
        data,
      })
    },
    uploadV3AnalysisTests: ({ dispatch }) => {
      const { data } = v3AnalysisTests
      const docID = '-analysis-tests-'

      dispatch('transferData', {
        docID,
        data,
      })
    },
    uploadV3ProjectTypes: ({ dispatch }) => {
      const { data } = v3ProjectTypes
      const docID = '-project-types-'

      dispatch('transferData', {
        docID,
        data,
      })
    },
    uploadV3ProjectOptions: ({ dispatch }) => {
      const { data } = v3ProjectOptions
      const docID = '-project-options-'

      dispatch('transferData', {
        docID,
        data,
      })
    },
    pahUpdatePAHAnalysisTests: ({ dispatch, getters }) => {
      // 1. Jan. 2024 - New rules for PAH's
      const { analysisTestsAsArrayV3 } = getters

      const newAnalysisTests: IAnalysisTestV3[] = []

      // Get unchanged data
      analysisTestsAsArrayV3.forEach((analysisTest: IAnalysisTestV3) => {
        if (!['PAH_sum', 'PAH_benzo_a_anthracen', 'PAH_chrysen'].includes(analysisTest.id)) {
          newAnalysisTests.push(analysisTest)
        }
      })

      // Add new data
      newAnalysisTests.push({
        id: 'PAH_sum',
        translation: 'PAH_SUM',
        type: 'value-based',
        maxValue: 0,
        minValue: 4,
        unit: 'mg/kg',
        stancodeID: 1335,
      })

      newAnalysisTests.push({
        id: 'PAH_benzo_a_anthracen',
        translation: 'PAH_BENZO_A_ANTHRACEN',
        type: 'value-based',
        maxValue: 1000,
        minValue: 0,
        unit: 'mg/kg',
        stancodeID: 232,
      })

      newAnalysisTests.push({
        id: 'PAH_chrysen',
        translation: 'PAH_CHRYSENE',
        type: 'value-based',
        maxValue: 1000,
        minValue: 0,
        unit: 'mg/kg',
        stancodeID: 1149,
      })

      // Save data
      const docID = '-analysis-tests-'

      dispatch('transferData', {
        docID,
        data: newAnalysisTests,
      })
    },
    pahUpdatePAHAnalysisTestGroup: ({ dispatch, getters }) => {
      // 1. Jan. 2024 - New rules for PAH's
      const { analysisTestGroupsAsArrayV3 } = getters

      const newAnalysisTestGroups: IAnalysisTestGroupV3[] = []

      // Get unchanged data
      analysisTestGroupsAsArrayV3.forEach((atg: IAnalysisTestGroupV3) => {
        if (atg.id !== 'PAH') {
          newAnalysisTestGroups.push(atg)
        }
      })

      // Add new data
      newAnalysisTestGroups.push({
        id: 'PAH',
        order: 8,
        sampleTypeID: 'ST-3',
        translation: 'SAMPLE_9PAH',
        analysisTestIDs: [
          'PAH_sum',
          'PAH_naf',
          'PAH_benzo_a_anthracen',
          'PAH_chrysen',
          'PAH_benzo_b',
          'PAH_benzo_j',
          'PAH_benzo_k',
          'PAH_benzo_a',
          'PAH_dibenzo_a_h',
        ],
      })

      // Save data
      const docID = '-analysis-test-groups-'

      dispatch('transferData', {
        docID,
        data: newAnalysisTestGroups,
      })
    },
    pahUpdateLabColumnNames: ({ getters, dispatch }) => {
      const { laboratoriesAsArrayV3 } = getters

      // EuroFins
      const euroFinsLab = laboratoriesAsArrayV3.find((x: ILaboratoryV3) => x.id === 'euroFins')

      const newEuroFinsLab: ILaboratoryV3 = clone(euroFinsLab)

      const newListEuroFins: {
        analysisID: string
        columnName: string
      }[] = []

      // Get unchanged data
      newEuroFinsLab.excelFileColumnNames.forEach((item) => {
        if (!item.analysisID.includes('PAH_')) {
          newListEuroFins.push(item)
        }
      })

      // Add new data
      newListEuroFins.push({
        analysisID: 'PAH_sum',
        columnName: 'PAH sum',
      })
      newListEuroFins.push({
        analysisID: 'PAH_naf',
        columnName: 'Naphthalen',
      })
      newListEuroFins.push({
        analysisID: 'PAH_dibenzo_a_h',
        columnName: 'Dibenz(a,h)anthracen',
      })
      newListEuroFins.push({
        analysisID: 'PAH_benzo_a',
        columnName: 'Benzo(a)pyren',
      })
      newListEuroFins.push({
        analysisID: 'PAH_chrysen',
        columnName: 'Chrysen',
      })
      newListEuroFins.push({
        analysisID: 'PAH_benzo_b',
        columnName: 'Benzo(b)fluoranthen',
      })
      newListEuroFins.push({
        analysisID: 'PAH_benzo_j',
        columnName: 'Benzo(j)fluoranthen',
      })
      newListEuroFins.push({
        analysisID: 'PAH_benzo_k',
        columnName: 'Benzo(k)fluoranthen',
      })
      newListEuroFins.push({
        analysisID: 'PAH_benzo_a_anthracen',
        columnName: 'Benzo(a)anthracen',
      })

      newEuroFinsLab.excelFileColumnNames = newListEuroFins

      // Højvang
      const hojvangLab = laboratoriesAsArrayV3.find((x: ILaboratoryV3) => x.id === 'hojvang')

      const newHojvangLab: ILaboratoryV3 = clone(hojvangLab)

      const newListHojvang: {
        analysisID: string
        columnName: string
      }[] = []

      // Get unchanged data
      newHojvangLab.excelFileColumnNames.forEach((item) => {
        if (!item.analysisID.includes('PAH_')) {
          newListHojvang.push(item)
        }
      })

      // Add new data
      newListHojvang.push({
        analysisID: 'PAH_sum',
        columnName: 'Sum PAH',
      })
      newListHojvang.push({
        analysisID: 'PAH_naf',
        columnName: 'Naphthalen',
      })
      newListHojvang.push({
        analysisID: 'PAH_dibenzo_a_h',
        columnName: 'Dibenz(a,h)anthracen',
      })
      newListHojvang.push({
        analysisID: 'PAH_benzo_a',
        columnName: 'Benzo(a)pyren',
      })
      newListHojvang.push({
        analysisID: 'PAH_chrysen',
        columnName: 'Chrysen',
      })
      newListHojvang.push({
        analysisID: 'PAH_benzo_b',
        columnName: 'Benz(b+j)fluoranthen',
      })
      newListHojvang.push({
        analysisID: 'PAH_benzo_j',
        columnName: 'Benz(b+j)fluoranthen',
      })
      newListHojvang.push({
        analysisID: 'PAH_benzo_k',
        columnName: 'Benz(k)fluoranthen',
      })
      newListHojvang.push({
        analysisID: 'PAH_benzo_a_anthracen',
        columnName: 'Benzo(a)anthracen',
      })

      newHojvangLab.excelFileColumnNames = newListHojvang

      // Save data
      const docID = '-laboratories-'

      dispatch('transferData', {
        docID,
        data: [newEuroFinsLab, newHojvangLab],
      })
    },
    pahUpdateAddV1List: ({ dispatch }) => {
      const pahV1AnalysisTestIDs = [
        'PAH_naf',
        'PAH_benzo_a',
        'PAH_benzo_b',
        'PAH_benzo_j',
        'PAH_benzo_k',
        'PAH_dibenzo_a_h',
      ]

      // Save data
      const docID = '-pah-v1-analysis-test-id-list-'

      dispatch('transferData', {
        docID,
        data: pahV1AnalysisTestIDs,
      })
    },
    add6Metals: ({ dispatch }) => {
      // Update sample types
      const { data: sampleTypesData } = add6MetalsSampleTypes

      dispatch('transferData', {
        docID: '-sample-types-',
        data: sampleTypesData,
      })

      // Update analyses test groups
      const { data: analysesTestGroupData } = add6MetalsAnalysesTestGroups

      dispatch('transferData', {
        docID: '-analysis-test-groups-',
        data: analysesTestGroupData,
      })
    },
    addLye: ({ dispatch }) => {
      // Update coatings
      const {
        data: coatingsData,
        idPrefix: coatingsIDPrefix,
        nextID: coatingsNextID,
      } = addLyeCoatings

      dispatch('transferData', {
        docID: '-coatings-',
        data: coatingsData,
        idPrefix: coatingsIDPrefix,
        nextID: coatingsNextID,
      })

      // Update coating types
      const {
        data: coatingTypesData,
        idPrefix: coatingTypesIDPrefix,
        nextID: coatingTypesNextID,
      } = addLyeCoatingTypes

      dispatch('transferData', {
        docID: '-coating-types-',
        data: coatingTypesData,
        idPrefix: coatingTypesIDPrefix,
        nextID: coatingTypesNextID,
      })
    },
  },
}
