interface ITagConstructorV3 {
  id: string
  translation?: string
}

export class TagV3 {
  id: string
  translation: string

  constructor({
    id,
    translation,
  }: ITagConstructorV3) {
    this.id = id
    this.translation = translation ?? ''
  }
}
