import { ITypeQuestionV3, TypeV3 } from '@/globals/javascript/models/resources-version-3/TypeV3'
import store from '@/store'

interface IGroupQuestionConstructorV3 {
  id: string
  translation?: string
  questions?: ITypeQuestionV3[]
}

export class GroupQuestionV3 {
  id: string
  translation: string
  questions: ITypeQuestionV3[]

  constructor({
    id,
    translation,
    questions,
  }: IGroupQuestionConstructorV3) {
    this.id = id
    this.translation = translation ?? ''
    this.questions = questions ?? []
  }

  getNoOfTypes(): number {
    let count = 0
    const { typesAsArrayV3 } = store.getters

    typesAsArrayV3.forEach((type: TypeV3) => {
      const isUsingQuestion = type.questions.find((x: ITypeQuestionV3) => x.questionID === this.id)
      if (isUsingQuestion) {
        count += 1
      }
    })

    return count
  }
}
