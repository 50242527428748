
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import LoadingPage from './components/LoadingPage.vue'

export default defineComponent({
  name: 'App',
  computed: {
    ...mapGetters([
      'isPageLoading',
      'kasperTest',
    ]),
  },
  components: {
    LoadingPage,
  },
})
