import { GroupQuestionV3 } from '@/globals/javascript/models/resources-version-3/GroupQuestionV3'
import { TagV3 } from '@/globals/javascript/models/resources-version-3/TagV3'
import { ITypeQuestionV3, TypeV3 } from '@/globals/javascript/models/resources-version-3/TypeV3'
import { mixWB } from '@/globals/javascript/utils/mixins'
import store from '@/store'
import { ISampleTypeV3 } from '@/types/resources-version-3'

interface IQuestionV3Constructor {
  id: string
  translation?: string
  year?: number
  sampleIDs?: string[]
  tagIDs?: string[]
  assessmentIDs?: string[]
}

export class QuestionV3 {
  id: string
  translation: string
  year: number
  sampleIDs: string[]
  tagIDs: string[]
  assessmentIDs: string[]

  constructor({
    id,
    translation,
    year,
    sampleIDs,
    tagIDs,
    assessmentIDs,
  }: IQuestionV3Constructor) {
    this.id = id
    this.translation = translation ?? ''
    this.year = year ?? 0
    this.sampleIDs = sampleIDs ?? []
    this.tagIDs = tagIDs ?? []
    this.assessmentIDs = assessmentIDs ?? []
  }

  getTitle(): string {
    if (this.year) {
      return mixWB('FROM_BEFORE_X', [this.year.toString()])
    }

    return mixWB(this.translation)
  }

  // eslint-disable-next-line class-methods-use-this
  getSamples(): string {
    return this.sampleIDs.map((sampleID: string) => {
      const { sampleTypesAsArrayV3 } = store.getters
      const sample: ISampleTypeV3 = sampleTypesAsArrayV3.find(
        (x: ISampleTypeV3) => x.id === sampleID,
      )

      return mixWB(sample.translation)
    }).join(', ')
  }

  // eslint-disable-next-line class-methods-use-this
  getTags(): string {
    const tags = []

    if (this.year) {
      tags.push(mixWB('BEFORE_X', [this.year.toString()]))
    }

    this.tagIDs.forEach((tagID: string) => {
      const { tagsAsArrayV3 } = store.getters
      const tag: TagV3 = tagsAsArrayV3.find((x: TagV3) => x.id === tagID)

      if (tag) {
        tags.push(mixWB(tag.translation))
      }
    })

    return tags.sort().join(', ')
  }

  // eslint-disable-next-line class-methods-use-this
  getAssessments(): string {
    return this.assessmentIDs.map((sampleID: string) => {
      const { sampleTypesAsArrayV3 } = store.getters
      const sample: ISampleTypeV3 = sampleTypesAsArrayV3.find(
        (x: ISampleTypeV3) => x.id === sampleID,
      )

      return mixWB(sample.translation)
    }).join(', ')
  }

  getNoOfTypes(): number {
    let count = 0
    const { typesAsArrayV3 } = store.getters

    typesAsArrayV3.forEach((type: TypeV3) => {
      const isUsingQuestion = type.questions.find((x: ITypeQuestionV3) => x.questionID === this.id)
      if (isUsingQuestion) {
        count += 1
      }
    })

    return count
  }

  getNoOfGroups(): number {
    let count = 0
    const { groupQuestionsAsArrayV3 } = store.getters

    groupQuestionsAsArrayV3.forEach((groupQuestion: GroupQuestionV3) => {
      const isUsingQuestion = groupQuestion.questions.find(
        (x) => x.questionID === this.id,
      )
      if (isUsingQuestion) {
        count += 1
      }
    })

    return count
  }
}
