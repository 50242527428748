export interface ICustomRoomV2 {
  name: string
  categoryID: string
  proTypeID: string
  screeningID: string
  accountID: string
  userID: string
  createdAt: number
}

export class CustomRoomV2 implements ICustomRoomV2 {
  name: string
  categoryID: string
  proTypeID: string
  screeningID: string
  accountID: string
  userID: string
  createdAt: number

  constructor({
    name,
    categoryID,
    proTypeID,
    screeningID,
    accountID,
    userID,
    createdAt,
  }: ICustomRoomV2) {
    this.name = name || ''
    this.categoryID = categoryID || ''
    this.proTypeID = proTypeID || ''
    this.screeningID = screeningID || ''
    this.accountID = accountID || ''
    this.userID = userID || ''
    this.createdAt = createdAt || NaN
  }
}
