import store, { Store } from '@/store'
import { Role } from '@/types'
import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
} from 'vue-router'
import { adminRoutes } from './admin/admin'
import {
  IChecklist,
  dispatchChecklist,
  checkAllIsLoadedPromise,
  checkAllIsLoaded,
} from './checklist'

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    redirect: '/admin',
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      requiresGuest: true,
    },
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
  },
  ...adminRoutes,
  {
    name: 'CatchAll',
    path: '/:pathMatch(.*)*',
    redirect: '/admin',
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export const waitForCurrentUser = ((store: Store): Promise<boolean> => new Promise((resolve) => {
  const waitForLoad = setInterval(() => {
    const { currentUser, isLoggedIn } = store.getters

    if (!isLoggedIn || !currentUser) return

    clearInterval(waitForLoad)
    resolve(true)
  }, 50)
}))

export const waitForAppInitialized = ((store: Store): Promise<boolean> => new Promise((resolve) => {
  const waitForLoad = setInterval(() => {
    const { appIsInitialized } = store.getters
    if (!appIsInitialized) return

    clearInterval(waitForLoad)
    resolve(true)
  }, 50)
}))

router.beforeEach(async (to, from, next) => {
  await waitForAppInitialized(store)

  if (!to.meta.requiresGuest && !store.getters.isLoggedIn) {
    router.push({ name: 'Login' })
    next()
    return
  }
  next()
})

// Wait for user and check for requirements
router.beforeEach(async (to, from, next) => {
  if (to.meta.requiresGuest) {
    next()
    return
  }

  await waitForCurrentUser(store)
  const { currentUser } = store.getters

  // Check that user is Holdensen user
  if (!currentUser.isActive || currentUser.accountID !== 'bpZulvjI5VnFL9zVO0zb') {
    store.dispatch('logout')
    router.push({ name: 'Login' })
  }

  // Check if page requires admin
  if (to.meta.requiresAdmin && !currentUser?.isAdmin) {
    router.push({ name: 'AllProjects' })
  }

  next()
})

// Check logged in or not
router.beforeEach(async (to, from, next) => {
  await waitForAppInitialized(store)

  const { isLoggedIn } = store.getters

  // Make sure user is logged in
  if (!isLoggedIn && !to.meta.requiresGuest) {
    router.push({ name: 'SignIn' })
    return
  }

  // Make sure user is not logged in
  if (isLoggedIn && to.meta.requiresGuest) {
    router.push({ name: 'AllProjects' })
    return
  }

  next()
})

// Make sure all things from checklist are loaded
router.beforeEach(async (to, from, next) => {
  const { checklist } = to.meta

  if (!checklist) {
    store.dispatch('setPageLoadingStatus', false)
    next()
    return
  }

  // Quick check for all is loaded
  const allLoaded = checkAllIsLoaded(checklist as IChecklist)
  if (allLoaded) {
    store.dispatch('setPageLoadingStatus', false)
    next()
    return
  }

  store.dispatch('setPageLoadingStatus', true)

  // Load required things from checklist
  dispatchChecklist(checklist as IChecklist)
  await checkAllIsLoadedPromise(checklist as IChecklist)
  store.dispatch('setPageLoadingStatus', false)
  next()
})

export default router
