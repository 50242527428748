import store from '@/store'
import { TUser } from '@/types'
import { UnitTypes } from '@/types/general'
import { ILogV2 } from '@/types/resources-version-2'
import { mixWB } from '@/globals/javascript/utils/mixins'

export interface IMaterialFractionV2 {
  wdcID: string
  translation: string
  ewcID: string
  fractionID: string
}

export interface IMaterialFractionsV2 {
  fractions: {
    [key: string]: IMaterialFractionV2
    clean: {
      wdcID: 'WDC-1'
      translation: 'CLEAN'
      ewcID: string
      fractionID: string
    },
    contaminated: {
      wdcID: 'WDC-2'
      translation: 'CONTAMINATED_WASTE'
      ewcID: string
      fractionID: string
    },
    hazardous: {
      wdcID: 'WDC-3'
      translation: 'HAZARDOUS_WASTE'
      ewcID: string
      fractionID: string
    }
    asbestos: {
      wdcID: 'WDC-4'
      translation: 'ASBESTOS_WASTE'
      ewcID: string
      fractionID: string
    },
    hazardousAsbestos: {
      wdcID: 'WDC-5'
      translation: 'ASBESTOS_AND_HAZARDOUS_WASTE'
      ewcID: string
      fractionID: string
    }
  }
  source: string,
}

export interface IMaterialConstructorV2 {
  id: string
  translation: string
  metaData: {
    co2: {
      value: number
      unit?: UnitTypes.TONS_PER_M3
    }
    density: {
      value: number
      unit?: UnitTypes.TONS_PER_M3
    }
  }
  fractions: IMaterialFractionsV2
  options: {
    isMetal: boolean
    excludeFromPCBScreening: boolean
  }
  logs: ILogV2[]
}

export interface IMaterialV2 extends IMaterialConstructorV2 {
  getUser(): TUser | undefined
}

export class MaterialV2 implements IMaterialV2 {
  id: string
  translation: string
  metaData: {
    co2: {
      value: number
      unit?: UnitTypes.TONS_PER_M3
    }
    density: {
      value: number
      unit?: UnitTypes.TONS_PER_M3
    }
  }
  fractions: IMaterialFractionsV2
  options: {
    isMetal: boolean
    excludeFromPCBScreening: boolean
  }
  logs: ILogV2[]

  constructor({
    id,
    translation,
    metaData,
    fractions,
    options,
    logs,
  }: IMaterialConstructorV2) {
    this.id = id
    this.translation = translation || ''
    this.metaData = {
      co2: {
        value: metaData.co2.value || 0,
        unit: UnitTypes.TONS_PER_M3,
      },
      density: {
        value: metaData.density.value || 0,
        unit: UnitTypes.TONS_PER_M3,
      },
    }
    this.fractions = {
      fractions: {
        clean: {
          wdcID: 'WDC-1',
          translation: 'CLEAN',
          ewcID: fractions?.fractions?.clean.ewcID || '',
          fractionID: fractions?.fractions?.clean.fractionID || '',
        },
        contaminated: {
          wdcID: 'WDC-2',
          translation: 'CONTAMINATED_WASTE',
          ewcID: fractions?.fractions?.contaminated.ewcID || '',
          fractionID: fractions?.fractions?.contaminated.fractionID || '',
        },
        hazardous: {
          wdcID: 'WDC-3',
          translation: 'HAZARDOUS_WASTE',
          ewcID: fractions?.fractions?.hazardous.ewcID || '',
          fractionID: fractions?.fractions?.hazardous.fractionID || '',
        },
        asbestos: {
          wdcID: 'WDC-4',
          translation: 'ASBESTOS_WASTE',
          ewcID: fractions?.fractions?.asbestos.ewcID || '',
          fractionID: fractions?.fractions?.asbestos.fractionID || '',
        },
        hazardousAsbestos: {
          wdcID: 'WDC-5',
          translation: 'ASBESTOS_AND_HAZARDOUS_WASTE',
          ewcID: fractions?.fractions?.hazardousAsbestos.ewcID || '',
          fractionID: fractions?.fractions?.hazardousAsbestos.fractionID || '',
        },
      },
      source: fractions?.source || '',
    }
    this.options = {
      isMetal: options?.isMetal || false,
      excludeFromPCBScreening: options?.excludeFromPCBScreening || false,
    }
    this.logs = logs || []
  }

  getUser() {
    if (!this.logs.length) {
      return undefined
    }

    const { getUserByID } = store.getters

    return getUserByID(this.logs[this.logs.length - 1].userID)
  }

  static newInstance(id: string): IMaterialV2 {
    return new MaterialV2({
      id,
      translation: '',
      metaData: {
        co2: {
          value: 0,
        },
        density: {
          value: 0,
        },
      },
      fractions: {
        fractions: {
          clean: {
            wdcID: 'WDC-1',
            translation: 'CLEAN',
            ewcID: '',
            fractionID: '',
          },
          contaminated: {
            wdcID: 'WDC-2',
            translation: 'CONTAMINATED_WASTE',
            ewcID: '',
            fractionID: '',
          },
          hazardous: {
            wdcID: 'WDC-3',
            translation: 'HAZARDOUS_WASTE',
            ewcID: '',
            fractionID: '',
          },
          asbestos: {
            wdcID: 'WDC-4',
            translation: 'ASBESTOS_WASTE',
            ewcID: '',
            fractionID: '',
          },
          hazardousAsbestos: {
            wdcID: 'WDC-5',
            translation: 'ASBESTOS_AND_HAZARDOUS_WASTE',
            ewcID: '',
            fractionID: '',
          },
        },
        source: '',
      },
      options: {
        isMetal: false,
        excludeFromPCBScreening: false,
      },
      logs: [],
    })
  }

  static getTitleFromID(id: string): string {
    const { materialsAsArrayV2 } = store.getters
    const material: IMaterialV2 = materialsAsArrayV2.find((x: IMaterialV2) => x.id === id)
    return material ? mixWB(material.translation) : ''
  }
}
