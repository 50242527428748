export interface ICustomTypeV2 {
  customTypeName: string
  customTypeWDGID: string
  sampleIDs: string[]
  categoryID: string
  proTypeID: string
  screeningID: string
  accountID: string
  userID: string
  resourceTypeID: string
  hasCoating: boolean
  amountType: string
  createdAt: number
}

export class CustomTypeV2 implements ICustomTypeV2 {
  customTypeName: string
  customTypeWDGID: string
  sampleIDs: string[]
  categoryID: string
  proTypeID: string
  screeningID: string
  accountID: string
  userID: string
  resourceTypeID: string
  hasCoating: boolean
  amountType: string
  createdAt: number

  constructor({
    customTypeName,
    customTypeWDGID,
    sampleIDs,
    categoryID,
    proTypeID,
    screeningID,
    accountID,
    userID,
    resourceTypeID,
    hasCoating,
    amountType,
    createdAt,
  }: ICustomTypeV2) {
    this.customTypeName = customTypeName || ''
    this.customTypeWDGID = customTypeWDGID || ''
    this.sampleIDs = sampleIDs || []
    this.categoryID = categoryID || ''
    this.proTypeID = proTypeID || ''
    this.screeningID = screeningID || ''
    this.accountID = accountID || ''
    this.userID = userID || ''
    this.resourceTypeID = resourceTypeID || ''
    this.hasCoating = hasCoating || false
    this.amountType = amountType || ''
    this.createdAt = createdAt || NaN
  }
}
