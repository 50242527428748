interface ICoatingTypeV3Constructor {
  id: string
  translation?: string
}

export class CoatingTypeV3 {
  id: string
  translation: string

  constructor({
    id,
    translation,
  }: ICoatingTypeV3Constructor) {
    this.id = id
    this.translation = translation ?? ''
  }
}
