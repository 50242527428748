import store from '@/store'
import { ICategoryTypeV2 } from './CategoryTypeV2'

export interface ICategoryConstructorV2 {
  id: string
  translation: string
  areaID: string
  position: number
  connectedMaterialIDs: string[]
  connectedTypeIDs: string[]
  options: {
    askForPlaster: boolean
    askForWallpaper: boolean
    isActive: boolean
    isArchived: boolean
  }
}

export interface ICategoryV2 extends ICategoryConstructorV2 {
  getCategoryTypes(): ICategoryTypeV2[]
}

export class CategoryV2 implements ICategoryV2 {
  id: string
  translation: string
  areaID: string
  position: number
  connectedMaterialIDs: string[]
  connectedTypeIDs: string[]
  options: {
    askForPlaster: boolean
    askForWallpaper: boolean
    isActive: boolean
    isArchived: boolean
  }

  constructor({
    id = '',
    translation,
    areaID,
    position,
    connectedMaterialIDs,
    connectedTypeIDs,
    options,
  }: ICategoryConstructorV2) {
    this.id = id
    this.translation = translation || ''
    this.areaID = areaID || ''
    this.position = position
    this.connectedMaterialIDs = connectedMaterialIDs || []
    this.connectedTypeIDs = connectedTypeIDs || []
    this.options = {
      askForPlaster: options?.askForPlaster || false,
      askForWallpaper: options?.askForWallpaper || false,
      isActive: options?.isActive || false,
      isArchived: options?.isArchived || false,
    }
  }

  getCategoryTypes(): ICategoryTypeV2[] {
    const { categoryTypesAsArrayV2 } = store.getters
    return categoryTypesAsArrayV2.filter((x: ICategoryTypeV2) => x.categoryID === this.id)
  }

  static newInstance(id: string): ICategoryV2 {
    return new CategoryV2({
      id,
      translation: '',
      areaID: '',
      position: 1,
      connectedMaterialIDs: [],
      connectedTypeIDs: [],
      options: {
        askForPlaster: false,
        askForWallpaper: false,
        isActive: false,
        isArchived: false,
      },
    })
  }
}
