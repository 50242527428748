interface IRoomConstructorV3 {
  id: string
  translation?: string
  suggestionText?: string
  options?: {
    isActive: boolean
    isStandard: boolean
  }
}

export class RoomV3 {
  id: string
  translation: string
  suggestionText: string
  options: {
    isActive: boolean
    isStandard: boolean
  }

  constructor({
    id,
    translation,
    suggestionText,
    options,
  }: IRoomConstructorV3) {
    this.id = id
    this.translation = translation ?? ''
    this.suggestionText = suggestionText ?? ''
    this.options = {
      isActive: options?.isActive ?? false,
      isStandard: options?.isStandard ?? false,
    }
  }
}
