import store from '@/store'
import { TUser } from '@/types'
import { ILogV2 } from '@/types/resources-version-2'

export enum FracionTypesV2 {
  CLEAN = 'clean',
  CONTAMINATED = 'contaminated',
  OTHER = 'other',
}

export interface IFractionConstructorV2 {
  id: string
  translation: string
  types: FracionTypesV2[]
  avgPrice: number
  logs: ILogV2[]
}

export interface IFractionV2 extends IFractionConstructorV2 {
  getUser(): TUser | undefined
}

export class FractionV2 implements IFractionV2 {
  id: string
  translation: string
  types: FracionTypesV2[]
  avgPrice: number
  logs: ILogV2[]

  constructor({
    id = '',
    translation,
    types,
    avgPrice,
    logs,
  }: IFractionConstructorV2) {
    this.id = id
    this.translation = translation || ''
    this.types = types || []
    this.avgPrice = avgPrice || 0
    this.logs = logs || []
  }

  getUser() {
    if (!this.logs.length) {
      return undefined
    }

    const { getUserByID } = store.getters

    return getUserByID(this.logs[this.logs.length - 1].userID)
  }

  static newInstance(id: string): IFractionV2 {
    return new FractionV2({
      id,
      translation: '',
      types: [],
      avgPrice: 0,
      logs: [],
    })
  }
}
