import store from '@/store'
import { ISampleTypeV2 } from '@/types/resources-version-2'
import { mixWB } from '@/globals/javascript/utils/mixins'
import { ITagV2 } from './TagV2'
import { ITypeV2, ITypeQuestionV2 } from './TypeV2'

interface IQuestionConstructorV2 {
  id: string
  translation: string
  year: number
  sampleIDs: string[]
  tagIDs: string[]
  assessmentIDs: string[]
}

export interface IQuestionV2 extends IQuestionConstructorV2 {
  getTitle(): string
  getSamples(): string
  getTags(): string
  getAssessments(): string
  getNoOfTypes(): number
}

export class QuestionV2 implements IQuestionV2 {
  id: string
  translation: string
  year: number
  sampleIDs: string[]
  tagIDs: string[]
  assessmentIDs: string[]

  constructor({
    id,
    translation,
    year,
    sampleIDs,
    tagIDs,
    assessmentIDs,
  }: IQuestionConstructorV2) {
    this.id = id
    this.translation = translation || ''
    this.year = year || 0
    this.sampleIDs = sampleIDs || []
    this.tagIDs = tagIDs || []
    this.assessmentIDs = assessmentIDs || []
  }

  getTitle(): string {
    if (this.year) {
      return mixWB('FROM_BEFORE_X', [this.year.toString()])
    }

    return mixWB(this.translation)
  }

  getSamples(): string {
    return this.sampleIDs.map((sampleID: string) => {
      const { sampleTypesAsArrayV2 } = store.getters
      const sample: ISampleTypeV2 = sampleTypesAsArrayV2.find(
        (x: ISampleTypeV2) => x.id === sampleID,
      )

      return mixWB(sample.translation)
    }).join(', ')
  }

  getTags(): string {
    const tags = []

    if (this.year) {
      tags.push(mixWB('BEFORE_X', [this.year.toString()]))
    }

    this.tagIDs.forEach((tagID: string) => {
      const { tagsAsArrayV2 } = store.getters
      const tag: ITagV2 = tagsAsArrayV2.find((x: ITagV2) => x.id === tagID)

      tags.push(mixWB(tag.translation))
    })

    return tags.sort().join(', ')
  }

  getAssessments(): string {
    return this.assessmentIDs.map((sampleID: string) => {
      const { sampleTypesAsArrayV2 } = store.getters
      const sample: ISampleTypeV2 = sampleTypesAsArrayV2.find(
        (x: ISampleTypeV2) => x.id === sampleID,
      )

      return mixWB(sample.translation)
    }).join(', ')
  }

  getNoOfTypes(): number {
    let count = 0
    const { typesAsArrayV2 } = store.getters

    typesAsArrayV2.forEach((type: ITypeV2) => {
      const isUsingQuestion = type.questions.find((x: ITypeQuestionV2) => x.questionID === this.id)
      if (isUsingQuestion) {
        count += 1
      }
    })

    return count
  }

  static newInstance(id: string): IQuestionV2 {
    return new QuestionV2({
      id,
      translation: '',
      year: 0,
      sampleIDs: [],
      tagIDs: [],
      assessmentIDs: [],
    })
  }
}
