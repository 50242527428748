import { COLLECTIONS, db, firebase } from '@/firebase'
import { IRootState } from '@/store'
import { FunctionVoid } from '@/types/general'
import { Module } from 'vuex'

interface ISystemState {
  system:{
    id: string
    nextAccountNumberID: number
    nextQRLabelNumber: number
    nextUserNumberID: number
  }
  unsubscribeSystem?: FunctionVoid
}

export const systemStore: Module<ISystemState, IRootState> = {
  state: {
    system: {
      id: '',
      nextAccountNumberID: 0,
      nextQRLabelNumber: 0,
      nextUserNumberID: 0,
    },
    // Unsubscribers
    unsubscribeSystem: undefined,
  },
  mutations: {
    updateSystem: (state, { system, unsubscribe }) => {
      state.system = system
      if (!state.unsubscribeSystem) {
        state.unsubscribeSystem = unsubscribe
      }
    },
  },
  actions: {
    getSystem: ({ getters, commit }) => {
      if (getters.isSystemLoaded) {
        return
      }

      const unsubscribe = db
        .collection(COLLECTIONS.DB_SYSTEM)
        .doc('-root-')
        .onSnapshot((doc) => {
          const system = doc.data()
          commit('updateSystem', {
            system,
            unsubscribe,
          })
        })
    },
    getNextAccountNumberID: async () => {
      const accountRef = db
        .collection(COLLECTIONS.DB_SYSTEM)
        .doc('-root-')

      let nextAccountNumberID
      await db.runTransaction(async (transaction) => {
        const accountDoc = await transaction.get(accountRef)

        nextAccountNumberID = accountDoc.data()?.nextAccountNumberID
        transaction.update(accountRef, { nextAccountNumberID: nextAccountNumberID + 1 })
      })

      return nextAccountNumberID
    },
  },
  getters: {
    system: (state) => state.system,
    // Loaders
    isSystemLoaded: (state) => !!state.unsubscribeSystem,
  },
}
