// Enums
export enum UnitTypes {
  TONS = 'TONS',
  MILLIMETER = 'MILLIMETER_SHORT',
  TONS_PER_M3 = 'TONS_PER_M3'
}

export enum UnitTypeTranslations {
  M2 = 'M2',
  METERS = 'METERS',
  PCS = 'PCS',
}

export enum UnitFields {
  WEIGHT_PER_PIECE = 'WEIGHT_PER_PIECE',
  WEIGHT = 'WEIGHT',
  THICKNESS = 'THICKNESS',
  THICKNESS_SUGGESTIONS = 'THICKNESS_SUGGESTIONS',
  WIDTH = 'WIDTH',
  WIDTH_SUGGESTIONS = 'WIDTH_SUGGESTIONS',
  HEIGHT = 'HEIGHT',
}

// Types
export type FunctionVoid = () => void

// Interfaces
export interface ObjPairString {
  [key: string]: string
}
export interface ObjPairBoolean {
  [key: string]: boolean
}
export interface SelectOption {
  key: string
  value: string
}
export interface ProImageObject {
  createdAt: number
  height: number
  width: number
  path: string
}
