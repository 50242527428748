interface ICoatingV3Constructor {
  id: string
  translation?: string
  position?: number
  materialID?: 'M-51'
  sampleIDs?: string[]
  coatingTypeIDs?: string[]
  options?: {
    hasColorSelect: boolean
    isActive: boolean
  }
}

export class CoatingV3 {
  id: string
  translation: string
  position: number
  materialID: 'M-51'
  sampleIDs: string[]
  coatingTypeIDs: string[]
  options: {
    hasColorSelect: boolean
    isActive: boolean
  }

  constructor({
    id,
    translation,
    position,
    sampleIDs,
    coatingTypeIDs,
    options,
  }: ICoatingV3Constructor) {
    this.id = id
    this.translation = translation ?? ''
    this.position = position ?? 1
    this.materialID = 'M-51'
    this.sampleIDs = sampleIDs ?? []
    this.coatingTypeIDs = coatingTypeIDs ?? []
    this.options = {
      hasColorSelect: options?.hasColorSelect ?? false,
      isActive: options?.isActive ?? false,
    }
  }
}
