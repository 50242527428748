interface TypeGroupConstructorV3 {
  id: string
  translation?: string
  internalLabel?: string
  typeIDs?: string[]
  options?: {
    isActive: boolean
  }
}

export class TypeGroupV3 {
  id: string
  translation: string
  internalLabel: string
  typeIDs: string[]
  options: {
    isActive: boolean
  }

  constructor({
    id,
    translation,
    internalLabel,
    typeIDs,
    options,
  }: TypeGroupConstructorV3) {
    this.id = id
    this.translation = translation ?? ''
    this.internalLabel = internalLabel ?? ''
    this.typeIDs = typeIDs ?? []
    this.options = {
      isActive: options?.isActive ?? true,
    }
  }
}
