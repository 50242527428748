import { db } from '@/firebase'

export enum COLLECTIONS {
  DB_HUB_ACCOUNTS = 'HubAccounts',
  DB_ACCOUNTS = 'Accounts',
  DB_ADDRESS_IMAGES = 'AddressImages',
  DB_BUILDINGS = 'Buildings',
  DB_CHANGELOGS = 'Changelogs',
  DB_INTER_CONNECTIONS = 'InterConnections',
  DB_META_DATA = 'MetaData',
  DB_PCB_SCREENINGS = 'PCBScreenings',
  DB_REPORTS_V2 = 'Reports',
  DB_SAMPLES = 'Samples',
  DB_SCREENINGS = 'Screenings',
  DB_STATS = 'Stats',
  DB_TEST_RESULTS = 'TestResults',
  DB_TYPES = 'Types',
  DB_UNITS = 'Units',
  DB_USERS = 'Users',
  DB_LANGUAGES = 'Languages',
  DB_TRANSLATIONS = 'Translations',
  DB_COUNTRIES = 'Countries',
  DB_SYSTEM = 'System',
  DB_RESOURCES_V2 = 'Resources',
  DB_RESOURCES_V3 = 'ResourcesV3',
  DB_PROJECTS = 'Projects',
  DB_PRICES = 'Prices',
}

export const setIDOnCreate = (id: string, collection: COLLECTIONS): string => {
  if (id) {
    return id
  }
  const docRef = db
    .collection(collection)
    .doc()

  return docRef.id
}
