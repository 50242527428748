import { baseChecklist } from '@/router/checklist'

export const resourcesVerions2 = [
  {
    name: 'CategoriesV2',
    path: 'resources/v2/categories',
    meta: {
      checklist: baseChecklist,
      requiresAdmin: true,
    },
    component: () => import(/* webpackChunkName: "sync-to-dev" */ '../../views/Admin/ResourcesVersion2/CategoriesV2.vue'),
  },
  {
    name: 'CategoryEditV2',
    path: 'resources/v2/category/:categoryID',
    meta: {
      checklist: baseChecklist,
      requiresAdmin: true,
    },
    component: () => import(/* webpackChunkName: "sync-to-dev" */ '../../views/Admin/ResourcesVersion2/CategoryEditV2.vue'),
  },
  {
    name: 'TypesV2',
    path: 'resources/v2/types',
    meta: {
      checklist: baseChecklist,
      requiresAdmin: true,
    },
    component: () => import(/* webpackChunkName: "sync-to-dev" */ '../../views/Admin/ResourcesVersion2/TypesV2.vue'),
  },
  {
    name: 'TypeEditV2',
    path: 'resources/v2/type/:typeID',
    meta: {
      checklist: baseChecklist,
      requiresAdmin: true,
    },
    component: () => import(/* webpackChunkName: "sync-to-dev" */ '../../views/Admin/ResourcesVersion2/TypeEditV2.vue'),
  },
  {
    name: 'CategoryTypesV2',
    path: 'resources/v2/category-types',
    meta: {
      checklist: baseChecklist,
      requiresAdmin: true,
    },
    component: () => import(/* webpackChunkName: "sync-to-dev" */ '../../views/Admin/ResourcesVersion2/CategoryTypesV2.vue'),
  },
  {
    name: 'CategoryTypeEditV2',
    path: 'resources/v2/category-type/:categoryTypeID',
    meta: {
      checklist: baseChecklist,
      requiresAdmin: true,
    },
    component: () => import(/* webpackChunkName: "sync-to-dev" */ '../../views/Admin/ResourcesVersion2/CategoryTypeEditV2.vue'),
  },
  {
    name: 'MaterialsV2',
    path: 'resources/v2/materials',
    meta: {
      checklist: baseChecklist,
      requiresAdmin: true,
    },
    component: () => import(/* webpackChunkName: "sync-to-dev" */ '../../views/Admin/ResourcesVersion2/MaterialsV2.vue'),
  },
  {
    name: 'MaterialEditV2',
    path: 'resources/v2/material/:materialID',
    meta: {
      checklist: baseChecklist,
      requiresAdmin: true,
    },
    component: () => import(/* webpackChunkName: "sync-to-dev" */ '../../views/Admin/ResourcesVersion2/MaterialEditV2.vue'),
  },
  {
    name: 'FractionsV2',
    path: 'resources/v2/fractions',
    meta: {
      checklist: baseChecklist,
      requiresAdmin: true,
    },
    component: () => import(/* webpackChunkName: "sync-to-dev" */ '../../views/Admin/ResourcesVersion2/FractionsV2.vue'),
  },
  {
    name: 'FractionEditV2',
    path: 'resources/v2/fraction/:fractionID',
    meta: {
      checklist: baseChecklist,
      requiresAdmin: true,
    },
    component: () => import(/* webpackChunkName: "sync-to-dev" */ '../../views/Admin/ResourcesVersion2/FractionEditV2.vue'),
  },
  {
    name: 'TagsV2',
    path: 'resources/v2/tags',
    meta: {
      checklist: baseChecklist,
      requiresAdmin: true,
    },
    component: () => import(/* webpackChunkName: "sync-to-dev" */ '../../views/Admin/ResourcesVersion2/TagsV2.vue'),
  },
  {
    name: 'TagEditV2',
    path: 'resources/v2/tag/:tagID',
    meta: {
      checklist: baseChecklist,
      requiresAdmin: true,
    },
    component: () => import(/* webpackChunkName: "sync-to-dev" */ '../../views/Admin/ResourcesVersion2/TagEditV2.vue'),
  },
  {
    name: 'QuestionsV2',
    path: 'resources/v2/questions',
    meta: {
      checklist: baseChecklist,
      requiresAdmin: true,
    },
    component: () => import(/* webpackChunkName: "sync-to-dev" */ '../../views/Admin/ResourcesVersion2/QuestionsV2.vue'),
  },
  {
    name: 'QuestionEditV2',
    path: 'resources/v2/question/:questionID',
    meta: {
      checklist: baseChecklist,
      requiresAdmin: true,
    },
    component: () => import(/* webpackChunkName: "sync-to-dev" */ '../../views/Admin/ResourcesVersion2/QuestionEditV2.vue'),
  },
  {
    name: 'GroupQuestionEditV2',
    path: 'resources/v2/group-question/:groupQuestionID',
    meta: {
      checklist: baseChecklist,
      requiresAdmin: true,
    },
    component: () => import(/* webpackChunkName: "sync-to-dev" */ '../../views/Admin/ResourcesVersion2/GroupQuestionEditV2.vue'),
  },
  {
    name: 'CategoryTypeGroupsV2',
    path: 'resources/v2/category-type-groups',
    meta: {
      checklist: baseChecklist,
      requiresAdmin: true,
    },
    component: () => import(/* webpackChunkName: "sync-to-dev" */ '../../views/Admin/ResourcesVersion2/CategoryTypeGroupsV2.vue'),
  },
  {
    name: 'LaboratoriesAndAnalysesV2',
    path: 'resources/v2/laboratories-and-analyses',
    meta: {
      checklist: baseChecklist,
      requiresAdmin: true,
    },
    component: () => import(/* webpackChunkName: "sync-to-dev" */ '../../views/Admin/ResourcesVersion2/LaboratoriesAndAnalysesV2.vue'),
  },
  {
    name: 'CategoryTypeGroupEditV2',
    path: 'resources/v2/category-type-group/:categoryTypeGroupID',
    meta: {
      checklist: baseChecklist,
      requiresAdmin: true,
    },
    component: () => import(/* webpackChunkName: "sync-to-dev" */ '../../views/Admin/ResourcesVersion2/CategoryTypeGroupEditV2.vue'),
  },
  {
    name: 'OtherResources',
    path: 'resources/v2/other-resources',
    meta: {
      checklist: baseChecklist,
      requiresAdmin: true,
    },
    component: () => import(/* webpackChunkName: "sync-to-dev" */ '../../views/Admin/ResourcesVersion2/OtherV2.vue'),
  },
]
