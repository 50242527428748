import { TypeV3 } from '@/globals/javascript/models/resources-version-3/TypeV3'
import store from '@/store'
import { UnitTypes } from '@/types/general'
import { ILogV3 } from '@/types/resources-version-3'

export interface IMaterialFractionV3 {
  wdcID: string
  translation: string
  ewcID: string
  fractionID: string
}

export interface IMaterialFractionsV3 {
  fractions: {
    [key: string]: IMaterialFractionV3
    clean: {
      wdcID: 'WDC-1'
      translation: 'CLEAN'
      ewcID: string
      fractionID: string
    },
    contaminated: {
      wdcID: 'WDC-2'
      translation: 'CONTAMINATED_WASTE'
      ewcID: string
      fractionID: string
    },
    hazardous: {
      wdcID: 'WDC-3'
      translation: 'HAZARDOUS_WASTE'
      ewcID: string
      fractionID: string
    }
    asbestos: {
      wdcID: 'WDC-4'
      translation: 'ASBESTOS_WASTE'
      ewcID: string
      fractionID: string
    },
    hazardousAsbestos: {
      wdcID: 'WDC-5'
      translation: 'ASBESTOS_AND_HAZARDOUS_WASTE'
      ewcID: string
      fractionID: string
    }
  }
  source: string,
}

interface IMaterialV3Constructor {
  id: string
  translation?: string
  metaData?: {
    co2: {
      value: number
      unit?: UnitTypes.TONS_PER_M3
    }
    density: {
      value: number
      unit?: UnitTypes.TONS_PER_M3
    }
  }
  fractions?: IMaterialFractionsV3
  options?: {
    isMetal: boolean
    excludeFromPCBScreening: boolean
  }
  logs?: ILogV3[]
}

export class MaterialV3 {
  id: string
  translation: string
  metaData: {
    co2: {
      value: number
      unit?: UnitTypes.TONS_PER_M3
    }
    density: {
      value: number
      unit?: UnitTypes.TONS_PER_M3
    }
  }
  fractions: IMaterialFractionsV3
  options: {
    isMetal: boolean
    excludeFromPCBScreening: boolean
  }
  logs: ILogV3[]

  constructor({
    id,
    translation,
    metaData,
    fractions,
    options,
    logs,
  }: IMaterialV3Constructor) {
    this.id = id
    this.translation = translation ?? ''
    this.metaData = {
      co2: {
        value: metaData?.co2.value ?? 0,
        unit: UnitTypes.TONS_PER_M3,
      },
      density: {
        value: metaData?.density.value ?? 0,
        unit: UnitTypes.TONS_PER_M3,
      },
    }
    this.fractions = {
      fractions: {
        clean: {
          wdcID: 'WDC-1',
          translation: 'CLEAN',
          ewcID: fractions?.fractions?.clean.ewcID ?? '',
          fractionID: fractions?.fractions?.clean.fractionID ?? '',
        },
        contaminated: {
          wdcID: 'WDC-2',
          translation: 'CONTAMINATED_WASTE',
          ewcID: fractions?.fractions?.contaminated.ewcID ?? '',
          fractionID: fractions?.fractions?.contaminated.fractionID ?? '',
        },
        hazardous: {
          wdcID: 'WDC-3',
          translation: 'HAZARDOUS_WASTE',
          ewcID: fractions?.fractions?.hazardous.ewcID ?? '',
          fractionID: fractions?.fractions?.hazardous.fractionID ?? '',
        },
        asbestos: {
          wdcID: 'WDC-4',
          translation: 'ASBESTOS_WASTE',
          ewcID: fractions?.fractions?.asbestos.ewcID ?? '',
          fractionID: fractions?.fractions?.asbestos.fractionID ?? '',
        },
        hazardousAsbestos: {
          wdcID: 'WDC-5',
          translation: 'ASBESTOS_AND_HAZARDOUS_WASTE',
          ewcID: fractions?.fractions?.hazardousAsbestos.ewcID ?? '',
          fractionID: fractions?.fractions?.hazardousAsbestos.fractionID ?? '',
        },
      },
      source: fractions?.source ?? '',
    }
    this.options = {
      isMetal: options?.isMetal ?? false,
      excludeFromPCBScreening: options?.excludeFromPCBScreening ?? false,
    }
    this.logs = logs ?? []
  }

  getUser() {
    if (!this.logs.length) {
      return undefined
    }

    const { getUserByID } = store.getters

    return getUserByID(this.logs[this.logs.length - 1].userID)
  }

  getTypes(): TypeV3[] {
    const {
      typesAsArrayV3,
    } = store.getters

    return typesAsArrayV3.filter((x: TypeV3) => x.materialID === this.id)
  }
}
