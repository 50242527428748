import { COLLECTIONS, db } from '@/firebase'
import { ObjPairString } from '@/types/general'
import { Module } from 'vuex'
import { IRootState } from '.'

interface syncToDevState {
  areHubAccountsSyncing: boolean
  areAllV3ResourcesSyncing: boolean
  areAllV2ResourcesSyncing: boolean
  areTranslationsSyncing: boolean
  areCountriesSyncing: boolean
}

export const syncToDevStore: Module<syncToDevState, IRootState> = {
  state: {
    areHubAccountsSyncing: false,
    areAllV3ResourcesSyncing: false,
    areAllV2ResourcesSyncing: false,
    areTranslationsSyncing: false,
    areCountriesSyncing: false,
  },
  mutations: {
    updateHubAccountsLoadingState: (state, status) => {
      state.areHubAccountsSyncing = status
    },
    updateAllV2ResourcesLoadingState: (state, status) => {
      state.areAllV2ResourcesSyncing = status
    },
    updateAllV3ResourcesLoadingState: (state, status) => {
      state.areAllV3ResourcesSyncing = status
    },
    updateTranslationsLoadingState: (state, status) => {
      state.areTranslationsSyncing = status
    },
    updateAllCountriesLoadingState: (state, status) => {
      state.areCountriesSyncing = status
    },
  },
  actions: {
    async syncHubAccounts({ commit, dispatch }) {
      commit('updateHubAccountsLoadingState', true)

      // Get all docs
      const snapshot = await db
        .collection(COLLECTIONS.DB_HUB_ACCOUNTS)
        .get()

      // Sync each doc
      const promises: Promise<boolean>[] = []
      snapshot.docs.forEach((doc) => {
        const promise = dispatch(
          'syncSingleDoc',
          {
            collection: COLLECTIONS.DB_HUB_ACCOUNTS,
            doc: doc.id,
            data: {
              ...doc.data(),
              name: `${ doc.data().name } from prod`,
              url: `${ doc.data().url }-from-prod`,
            },
          },
        )
        promises.push(promise)
      })

      Promise.all(promises)
        .then(() => {
          commit('updateHubAccountsLoadingState', false)
        })
        .catch((err) => {
          console.error(err)
          commit('updateHubAccountsLoadingState', false)
          alert('Something went wrong. See the console for more details.')
        })
    },
    async syncAllTranslations({ commit, dispatch }) {
      commit('updateTranslationsLoadingState', true)

      // Get all docs
      const snapshot = await db
        .collection(COLLECTIONS.DB_TRANSLATIONS)
        .get()

      // Sync each doc
      const promises: Promise<boolean>[] = []
      snapshot.docs.forEach((doc) => {
        const promise = dispatch(
          'syncSingleDoc',
          {
            collection: COLLECTIONS.DB_TRANSLATIONS,
            doc: doc.id,
            data: doc.data(),
          },
        )
        promises.push(promise)
      })

      Promise.all(promises)
        .then(() => {
          commit('updateTranslationsLoadingState', false)
        })
        .catch((err) => {
          console.error(err)
          commit('updateTranslationsLoadingState', false)
          alert('Something went wrong. See the console for more details.')
        })
    },
    async syncAllResourcesV3({ commit, dispatch }) {
      commit('updateAllV3ResourcesLoadingState', true)

      // Get all docs
      const snapshot = await db
        .collection(COLLECTIONS.DB_RESOURCES_V3)
        .get()

      // Sync each doc
      const promises: Promise<boolean>[] = []
      snapshot.docs.forEach((doc) => {
        const promise = dispatch(
          'syncSingleDoc',
          {
            collection: COLLECTIONS.DB_RESOURCES_V3,
            doc: doc.id,
            data: doc.data(),
          },
        )
        promises.push(promise)
      })

      Promise.all(promises)
        .then(() => {
          commit('updateAllV3ResourcesLoadingState', false)
        })
        .catch((err) => {
          console.error(err)
          commit('updateAllV3ResourcesLoadingState', false)
          alert('V3 resources: Something went wrong. See the console for more details.')
        })
    },
    async syncAllResourcesV2({ commit, dispatch }) {
      commit('updateAllV2ResourcesLoadingState', true)

      // Get all docs
      const snapshot = await db
        .collection(COLLECTIONS.DB_RESOURCES_V2)
        .get()

      // Sync each doc
      const promises: Promise<boolean>[] = []
      snapshot.docs.forEach((doc) => {
        const promise = dispatch(
          'syncSingleDoc',
          {
            collection: COLLECTIONS.DB_RESOURCES_V2,
            doc: doc.id,
            data: doc.data(),
          },
        )
        promises.push(promise)
      })

      Promise.all(promises)
        .then(() => {
          commit('updateAllV2ResourcesLoadingState', false)
        })
        .catch((err) => {
          console.error(err)
          commit('updateAllV2ResourcesLoadingState', false)
          alert('V2 resources: Something went wrong. See the console for more details.')
        })
    },
    async syncAllCountries({ commit, dispatch }) {
      commit('updateAllCountriesLoadingState', true)

      // Get all docs
      const snapshot = await db
        .collection(COLLECTIONS.DB_COUNTRIES)
        .get()

      // Sync each doc
      const promises: Promise<boolean>[] = []
      snapshot.docs.forEach((doc) => {
        const promise = dispatch(
          'syncSingleDoc',
          {
            collection: COLLECTIONS.DB_COUNTRIES,
            doc: doc.id,
            data: doc.data(),
          },
        )
        promises.push(promise)
      })

      Promise.all(promises)
        .then(() => {
          commit('updateAllCountriesLoadingState', false)
        })
        .catch((err) => {
          console.error(err)
          commit('updateAllCountriesLoadingState', false)
          alert('Something went wrong. See the console for more details.')
        })
    },
    syncSingleDoc: (store, {
      collection,
      doc,
      data,
    }): Promise<boolean> => new Promise((resolve, reject) => {
      const bodyData = {
        collection,
        doc,
        data,
      }

      const requestOptions: {[key: string]: ObjPairString | string} = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': '*',
        },
        body: JSON.stringify(bodyData),
      }

      fetch('https://europe-west3-milva-pro-dev.cloudfunctions.net/admin_shared_syncDevDB', requestOptions)
        .then((response) => response.json())
        .then((data) => {
          let errorText = ''
          // Errors
          if (data.message === 'not-dev') {
            errorText = 'Function is not called on DEV'
          }
          else if (data.message === 'failed') {
            console.error(data.error)
            errorText = data.error
          }

          if (errorText) {
            reject(new Error(errorText))
          }
          else {
            resolve(true)
          }
        })
    }),
  },
  getters: {
    areAllV3ResourcesSyncing: (state) => state.areAllV3ResourcesSyncing,
    areAllV2ResourcesSyncing: (state) => state.areAllV2ResourcesSyncing,
    areTranslationsSyncing: (state) => state.areTranslationsSyncing,
    areCountriesSyncing: (state) => state.areCountriesSyncing,
    areHubAccountsSyncing: (state) => state.areHubAccountsSyncing,
  },
}
